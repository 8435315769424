<template>
    <div>
      <a-card>
        <a-row :gutter="20">
          <a-col :span="4">
            <a-button type="primary" @click="addTopicVisible = true">新增话题</a-button>
          </a-col>
          <a-col :span="3">
            <a-select placeholder="请选择分类" style="width: 200px" @change="MomentChange">
              <a-select-option
                v-for="item in Catelist"
                :key="item.id"
                :value="item.id"
              >{{ item.name }}</a-select-option>
            </a-select>
          </a-col>

          <a-col :span="1">
            <a-button type="info" @click="getMomentList()">显示全部</a-button>
          </a-col>
        </a-row>

        <a-table
          rowKey="id"
          :columns="columns"
          :pagination="pagination"
          :dataSource="Topiclist"
          size="small"
          bordered
          @change="handleTableChange"
        >
          <template slot="action" slot-scope="data">
            <div class="actionSlot">
              <a-button type="primary" size="small" icon="edit" style="margin-right: 15px" @click="editTopic(data.id)">编辑</a-button>
              <a-button type="danger" size="small" icon="delete" style="margin-right: 15px" @click="deleteTopic(data.id)"
                >删除</a-button
              >
            </div>
          </template>
        </a-table>
      </a-card>

      <!-- 新增话题区域 -->
      <a-modal
        closable
        title="新增话题"
        :visible="addTopicVisible"
        width="60%"
        @ok="addTopicOk"
        @cancel="addTopicCancel"
        destroyOnClose
      >
        <a-form-model :model="newTopic" :rules="addTopicRules" ref="addTopicRef">
          <a-form-model-item label="话题名称" prop="name">
            <a-input v-model="newTopic.name"></a-input>
          </a-form-model-item>
          <a-form-model-item label="话题类型" prop="type">
            <a-input v-model="newTopic.type"></a-input>
          </a-form-model-item>
          <a-form-model-item label="话题icon" prop="url">
            <a-input v-model="newTopic.url"></a-input>
          </a-form-model-item>
          <a-form-model-item label="话题描述" prop="desc">
            <a-input v-model="newTopic.desc"></a-input>
          </a-form-model-item>
        </a-form-model>
      </a-modal>

      <!-- 编辑话题区域 -->
      <a-modal
        closable
        destroyOnClose
        title="编辑话题"
        :visible="editTopicVisible"
        width="60%"
        @ok="editTopicOk"
        @cancel="editTopicCancel"
      >
        <a-form-model :model="TopicInfo" :rules="TopicRules" ref="addTopicRef">
          <a-form-model-item label="话题名称" prop="name">
            <a-input v-model="TopicInfo.name"></a-input>
          </a-form-model-item>
          <a-form-model-item label="话题类型" prop="type">
            <a-input v-model="TopicInfo.type"></a-input>
          </a-form-model-item>
          <a-form-model-item label="话题icon" prop="url">
            <a-input v-model="TopicInfo.url"></a-input>
          </a-form-model-item>
          <a-form-model-item label="话题描述" prop="desc">
            <a-input v-model="TopicInfo.desc"></a-input>
          </a-form-model-item>
        </a-form-model>
      </a-modal>
    </div>
  </template>

  <script>
  const columns = [
    {
      title: 'id',
      dataIndex: 'id',
      width: '5%',
      key: 'id',
      align: 'center',
    },
    {
      title: '话题名',
      dataIndex: 'name',
      width: '20%',
      key: 'name',
      align: 'left',
    },
    {
      title: '话题描述',
      dataIndex: 'desc',
      width: '10%',
      key: 'desc',
      align: 'center',
    },
    {
      title: '话题type',
      dataIndex: 'type',
      width: '5%',
      key: 'type',
      align: 'center',
    },
    {
      title: '话题url',
      dataIndex: 'url',
      width: '20%',
      key: 'url',
      align: 'center',
    },
    {
      title: '操作',
      width: '10%',
      key: 'action',
      align: 'center',
      scopedSlots: { customRender: 'action' },
    },
  ]

  export default {
    data() {
      return {
        pagination: {
          pageSizeOptions: ['20', '50', '100'],
          pageSize: 20,
          total: 0,
          showSizeChanger: true,
          showTotal: (total) => `共${total}条`,
        },
        Catelist: [],
        Topiclist: [],
        TopicInfo: {
          name: '',
          type: '',
          url: '',
          desc: '',
          id: 0,
        },
        newTopic: {
          name: '',
          type: '',
          url: '',
          desc: '',
        },
        columns,
        queryParam: {
          pagesize: 20,
          pagenum: 0,
        },
        editVisible: false,
        TopicRules: {
          name: [
            {
              validator: (rule, value, callback) => {
                if (this.TopicInfo.name === '') {
                  callback(new Error('请输入话题名'))
                  return
                }
                callback()
              },
              trigger: 'blur',
            },
          ],
          type: [
            {
              validator: (rule, value, callback) => {
                if (this.TopicInfo.type === '') {
                  callback(new Error('请输入话题类型（0，数据结构，1，算法）'))
                  return
                }
                callback()
              },
              trigger: 'blur',
            },
          ],
          url: [
            {
              validator: (rule, value, callback) => {
                if (this.TopicInfo.url === '') {
                  callback(new Error('请输入话题封面url'))
                  return
                }
                callback()
              },
              trigger: 'blur',
            },
          ],
          desc: [
            {
              validator: (rule, value, callback) => {
                if (this.TopicInfo.desc === '') {
                  callback(new Error('请输入话题描述，英文名'))
                  return
                }
                callback()
              },
              trigger: 'blur',
            },
          ]
        },
        addTopicRules: {
          name: [
            {
              validator: (rule, value, callback) => {
                if (this.newTopic.name === '') {
                  callback(new Error('请输入话题名'))
                  return
                }
                callback()
              },
              trigger: 'blur',
            },
          ],
          type: [
            {
              validator: (rule, value, callback) => {
                if (this.newTopic.type === '') {
                  callback(new Error('请输入话题类型（0，数据结构，1，算法）'))
                  return
                }
                callback()
              },
              trigger: 'blur',
            },
          ],
          url: [
            {
              validator: (rule, value, callback) => {
                if (this.newTopic.url === '') {
                  callback(new Error('请输入话题封面url'))
                  return
                }
                callback()
              },
              trigger: 'blur',
            },
          ],
          desc: [
            {
              validator: (rule, value, callback) => {
                if (this.newTopic.desc === '') {
                  callback(new Error('请输入话题描述，英文名'))
                  return
                }
                callback()
              },
              trigger: 'blur',
            },
          ]
        },
        editTopicVisible: false,
        addTopicVisible: false,
      }
    },
    created() {
      this.getTopicList()
      this.getCateList()
    },
    methods: {
      // 获取话题列表
      async getTopicList() {
        const { data: res } = await this.$http.get('admin/topic', {
          pagesize: this.queryParam.pagesize,
          pagenum: this.queryParam.pagenum,
        })

        // if (res.code !== 200) {
        //   if (res.code === 1004 || 1005 || 1006 || 1007) {
        //     window.sessionStorage.clear()
        //     this.$router.push('/login')
        //   }
        //   this.$message.error(res.message)
        // }
        this.Topiclist = res.data
        this.pagination.total = res.total
      },
          // 获取分类
      async getCateList() {
        const { data: res } = await this.$http.get('admin/category')
        if (res.status !== 200) return this.$message.error(res.message)
        this.Catelist = res.data
        this.pagination.total = res.total
      },
      // 更改分页
      handleTableChange(pagination, filters, sorter) {
        var pager = { ...this.pagination }
        pager.current = pagination.current
        pager.pageSize = pagination.pageSize
        this.queryParam.pagesize = pagination.pageSize
        this.queryParam.pagenum = pagination.current

        if (pagination.pageSize !== this.pagination.pageSize) {
          this.queryParam.pagenum = 1
          pager.current = 1
        }
        this.pagination = pager
        this.getTopicList()
      },
      // 删除话题
      deleteTopic(id) {
        this.$confirm({
          title: '提示：请再次确认',
          content: '确定要删除该话题吗？一旦删除，无法恢复',
          onOk: async () => {
            const { data: res } = await this.$http.post(`topic/delete/${id}`)
            if (res.status != 200) return this.$message.error(res.message)
            this.$message.success('删除成功')
            this.getTopicList()
          },
          onCancel: () => {
            this.$message.info('已取消删除')
          },
        })
      },
      // 新增话题
      addTopicOk() {
        this.$refs.addTopicRef.validate(async (valid) => {
          if (!valid) return this.$message.error('参数不符合要求，请重新输入')
          const { data: res } = await this.$http.post('topic/add', {
            name: this.newTopic.name,
            desc: this.newTopic.desc,
            type: parseInt(this.newTopic.type),
            url:  this.newTopic.url,
          })
          if (res.status != 200) return this.$message.error(res.message)
          this.$refs.addTopicRef.resetFields()
          this.addTopicVisible = false
          this.$message.success('添加话题成功')
          await this.getTopicList()
        })
      },
      addTopicCancel() {
        this.$refs.addTopicRef.resetFields()
        this.addTopicVisible = false
        this.$message.info('新增话题已取消')
      },
      // 编辑话题
      async editTopic(id) {
        this.editTopicVisible = true
        const { data: res } = await this.$http.get(`topic/${id}`)
        this.TopicInfo = res.data
        this.TopicInfo.id = id
      },
      editTopicOk() {
        this.$refs.addTopicRef.validate(async (valid) => {
          if (!valid) return this.$message.error('参数不符合要求，请重新输入')
          const { data: res } = await this.$http.post(`topic/edit/${this.TopicInfo.id}`, {
            name: this.TopicInfo.name,
            desc: this.TopicInfo.desc,
            type: parseInt(this.TopicInfo.type),
            url:  this.TopicInfo.url,
          })
          if (res.status != 200) return this.$message.error(res.message)
          this.editTopicVisible = false
          this.$message.success('更新话题信息成功')
          this.getTopicList()
        })
      },
      editTopicCancel() {
        this.$refs.addTopicRef.resetFields()
        this.editTopicVisible = false
        this.$message.info('编辑已取消')
      },
    },
  }
  </script>

  <style scoped>
  .actionSlot {
    display: flex;
    max-height: 20px;
    justify-content: center;
  }
  </style>
