<template>
    <div>
      <a-card>
        <a-row :gutter="20">
          <a-col :span="5">
            <a-form-model-item label="文章缩略图" prop="img">
              <a-upload listType="picture" name="file" :action="upUrl" :headers="headers"
                @change="upChange">
                <a-button>
                  <a-icon type="upload" />点击上传
                </a-button>

                <template v-if="id">
                  <img :src="artInfo.img" style="width: 120px; height: 100px; margin-left: 15px" />
                </template>
              </a-upload>
            </a-form-model-item>
          </a-col>
        </a-row>
  
        <a-table
          rowKey="id"
          :columns="columns"
          :pagination="pagination"
          :dataSource="picList"
          size="small"
          bordered
          @change="handleTableChange"
        >   
          <span class="ArtImg" slot="img" slot-scope="img">
            <img :src="img" />
          </span>
        </a-table>
      </a-card>
    </div>
  </template>
  
  <script>
  import day from 'dayjs'
  import { Url } from '../../plugin/http'
  
  const columns = [
    {
      title: '链接',
      dataIndex: 'url',
      width: '50%',
      key: 'url',
      align: 'left',
    },
    {
      title: '时间',
      dataIndex: 'putTime',
      width: '20%',
      key: 'putTime',
      align: 'center',
    },
    {
      title: '类型',
      dataIndex: 'mimeType',
      width: '20%',
      key: 'mimeType',
      align: 'center',
    },
    {
      title: '大小',
      dataIndex: 'size',
      width: '20%',
      key: 'size',
      align: 'center',
    },
    {
      title: '图片',
      dataIndex: 'url',
      width: '20%',
      key: 'url',
      align: 'center',
      scopedSlots: { customRender: 'img' },
    }
  ]
  
  export default {
    data() {
      return {
        pagination: {
          pageSizeOptions: ['20', '50', '100'],
          pageSize: 20,
          total: 0,
          showSizeChanger: true,
          showTotal: (total) => `共${total}条`,
        },
        upUrl: Url + 'upload',
        headers: {},
        picList: [],
        columns,
        queryParam: {
          title: '',
          pagesize: 20,
          pagenum: 1,
        },
      }
    },
    mounted() {
        this.headers = { Authorization: `Bearer ${window.sessionStorage.getItem('token')}` }
    },
    created() {
      this.getPictures()
    },
    methods: {
      // 获取文章列表
      async getPictures() {
        const { data: res } = await this.$http.get('pictures', {
          params: {
            title: this.queryParam.title,
            pagesize: this.queryParam.pagesize,
            pagenum: this.queryParam.pagenum,
          },
        })
        if (res.status !== 200) {
          if (res.status === 1004 || 1005 || 1006 || 1007) {
            window.sessionStorage.clear()
            this.$router.push('/login')
          }
          this.$message.error(res.message)
        }
  
        this.picList = res.data
        this.pagination.total = res.total
      },
     // 上传图片
    upChange(info) {
      if (info.file.status !== 'uploading') {
      }
      if (info.file.status === 'done') {
        this.$message.success(`图片上传成功`)
        this.getPictures()
      } else if (info.file.status === 'error') {
        this.$message.error(`图片上传失败`)
      }
    },
      // 更改分页
      handleTableChange(pagination, filters, sorter) {
        var pager = { ...this.pagination }
        pager.current = pagination.current
        pager.pageSize = pagination.pageSize
        this.queryParam.pagesize = pagination.pageSize
        this.queryParam.pagenum = pagination.current
  
        if (pagination.pageSize !== this.pagination.pageSize) {
          this.queryParam.pagenum = 1
          pager.current = 1
        }
        this.pagination = pager
        this.getPictures()
      },

    },
  }
  </script>
  
  <style scoped>
  .actionSlot {
    display: flex;
    justify-content: center;
  }
  .ArtImg img {
    width: 30px;
    height: 30px;
  }
  </style>
  