<template>
    <div>
      <a-card>
        <a-row :gutter="20">
          <a-col :span="6">
            <a-input-search
              v-model="queryParam.title"
              placeholder="输入动态名查找"
              enter-button
              allowClear
              @search="getMomentList"
            />
          </a-col>
          <a-col :span="4">
            <a-button type="primary" @click="$router.push('/addmoment')">新增</a-button>
          </a-col>
  
          <a-col :span="3">
            <a-select placeholder="请选择分类" style="width: 200px" @change="MomentChange">
              <a-select-option
                v-for="item in Catelist"
                :key="item.id"
                :value="item.id"
              >{{ item.name }}</a-select-option>
            </a-select>
          </a-col>
          <a-col :span="1">
            <a-button type="info" @click="getMomentList()">显示全部</a-button>
          </a-col>
        </a-row>
  
        <a-table
          rowKey="id"
          :columns="columns"
          :pagination="pagination"
          :dataSource="Momentlist"
          size="small"
          bordered
          @change="handleTableChange"
        >
          <span class="MomentImg" slot="img" slot-scope="img">
            <img :src="img" />
          </span>
          <template slot="action" slot-scope="data">
            <div class="actionSlot">
              <a-button
                size="small"
                type="primary"
                icon="edit"
                style="margin-right: 15px"
                @click="$router.push(`/addmoment/${data.id}`)"
              >编辑</a-button>
              <a-button
                size="small"
                type="danger"
                icon="delete"
                style="margin-right: 15px"
                @click="deleteMoment(data.id)"
              >删除</a-button>
            </div>
          </template>
        </a-table>
      </a-card>
    </div>
  </template>
  
  <script>
  import day from 'dayjs'
  
  const columns = [
    {
      title: 'id',
      dataIndex: 'id',
      width: '5%',
      key: 'id',
      align: 'center',
    },
    {
      title: '作者ID',
      dataIndex: 'uid',
      width: '5%',
      key: 'uid',
      align: 'center',
    },
    {
      title: '话题ID',
      dataIndex: 'tid',
      width: '5%',
      key: 'tid',
      align: 'center',
    },
    {
      title: '父动态ID',
      dataIndex: 'parent_id',
      width: '5%',
      key: 'parent_id',
      align: 'center',
    },
    {
      title: '动态内容',
      dataIndex: 'content',
      width: '30%',
      key: 'content',
      align: 'left',
    },
    {
      title: '图片',
      dataIndex: 'image_url',
      width: '5%',
      key: 'image_url',
      align: 'center',
      scopedSlots: { customRender: 'img' },
    },
    {
      title: '发布时间',
      dataIndex: 'created_at',
      width: '10%',
      key: 'created_at',
      align: 'center',
      customRender: (val) => {
        return val ? day(val).format('YYYY年MM月DD日 HH:mm') : '暂无'
      },
    },
    {
      title: '操作',
      width: '2%',
      key: 'action',
      align: 'center',
      scopedSlots: { customRender: 'action' },
    },
  ]
  
  export default {
    data() {
      return {
        pagination: {
          pageSizeOptions: ['20', '50', '100'],
          pageSize: 20,
          total: 0,
          showSizeChanger: true,
          showTotal: (total) => `共${total}条`,
        },
        Catelist: [
        {
            id: 'cover',
            name: '封面',
        },
        {
            id: 'avatar',
            name: '头像',
        },
        {
            id: 'nickname',
            name: '昵称',
        },
        ],
        Momentlist: [],
        columns,
        queryParam: {
          title: '',
          pagesize: 20,
          pagenum: 1,
        },
      }
    },
    created() {
      this.getMomentList()
    },
    methods: {
      // 获取动态列表
      async getMomentList() {
        const { data: res } = await this.$http.get('admin/moment', {
          params: {
            title: this.queryParam.title,
            pagesize: this.queryParam.pagesize,
            pagenum: this.queryParam.pagenum,
          },
        })
        // if (res.status !== 200) {
        //   if (res.status === 1004 || 1005 || 1006 || 1007) {
        //     window.sessionStorage.clear()
        //     this.$router.push('/login')
        //   }
        //   this.$message.error(res.message)
        // }
  
        this.Momentlist = res.data
        this.pagination.total = res.total
      },
      // 更改分页
      handleTableChange(pagination, filters, sorter) {
        var pager = { ...this.pagination }
        pager.current = pagination.current
        pager.pageSize = pagination.pageSize
        this.queryParam.pagesize = pagination.pageSize
        this.queryParam.pagenum = pagination.current
  
        if (pagination.pageSize !== this.pagination.pageSize) {
          this.queryParam.pagenum = 1
          pager.current = 1
        }
        this.pagination = pager
        this.getMomentList()
      },
      // 删除动态
      deleteMoment(id) {
        this.$confirm({
          title: '提示：请再次确认',
          content: '确定要删除该动态吗？一旦删除，无法恢复',
          onOk: async () => {
            const { data: res } = await this.$http.delete(`moment/${id}`)
            if (res.status != 200) return this.$message.error(res.message)
            this.$message.success('删除成功')
            this.getMomentList()
          },
          onCancel: () => {
            this.$message.info('已取消删除')
          },
        })
      },
      // 查询分类下的动态
      MomentChange(value) {
        this.getMomentMoment(value)
      },
      async getMomentMoment(id) {
        const { data: res } = await this.$http.get(`admin/moment/${id}`, {
          params: { pagesize: this.queryParam.pagesize, pagenum: this.queryParam.pagenum },
        })
        if (res.status !== 200) return this.$message.error(res.message)
        this.Momentlist = res.data
        this.pagination.total = res.total
      },
    },
  }
  </script>
  
  <style scoped>
  .actionSlot {
    display: flex;
    justify-content: center;
  }
  .MomentImg {
    height: 100%;
    width: 100%;
  }
  
  .MomentImg img {
    width: 40px;
    height: 40px;
  }
  </style>
  