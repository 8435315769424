import Vue from 'vue'
import VueRouter from 'vue-router'
const Login = () => import('../views/Login.vue')
const Admin = () => import('../views/Admin.vue')

// 页面路由组件
const Index = () => import('../components/admin/Index.vue')
const AddArt = () => import('../components/article/AddArt.vue')
const ArtList = () => import('../components/article/ArtList.vue')
const CateList = () => import('../components/category/CateList.vue')
const UserList = () => import('../components/user/UserList.vue')
const Profile = () => import('../components/user/Profile.vue')
const CommentList = () => import('../components/comment/commentList.vue')
const AddQuestion = () => import('../components/question/AddQuestion.vue')
const QuestionDetail = () => import('../components/question/QuestionDetail.vue')
const QuestionList = () => import('../components/question/QuestionList.vue')
const UploadPicture = () => import('../components/upload/UploadPicture.vue')
const SettingList = () => import('../components/setting/SettingList.vue')
const FeedbackList = () => import('../components/feedback/FeedbackList.vue')
const ReportList = () => import('../components/report/ReportList.vue')
const ProductList = () => import('../components/product/ProductList.vue')
const TopicList = () => import('../components/topic/TopicList.vue')
const AddMoment = () => import('../components/moment/AddMoment.vue')
const MomentList = () => import('../components/moment/MomentList.vue')
const NotificationList = () => import('../components/notification/NotificationList.vue')
const MessageList = () => import('../components/message/MessageList.vue')
const TimeBillboardList = () => import('../components/billboard/TimeBillboardList.vue')
const TimeRecordList = () => import('../components/billboard/TimeRecordList.vue')
const ChannelList = () => import('../components/channel/ChannelList.vue')
const OrderList = () => import('../components/order/OrderList.vue')
const MomentAuditList = () => import('../components/audit/MomentAuditList.vue')
const UserAuditList = () => import('../components/audit/UserAuditList.vue')

// 路由重复点击捕获错误
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location, onResolve, onReject) {
  if (onResolve || onReject) return originalPush.call(this, location, onResolve, onReject)
  return originalPush.call(this, location).catch(err => err)
}

Vue.use(VueRouter)

const routes = [
  {
    path: '/login',
    name: 'login',
    meta: {
      title: '请登录'
    },
    component: Login
  },
  {
    path: '/',
    name: 'admin',
    meta: {
      title: '抖文科技 后台管理页面'
    },
    component: Admin,
    children: [
      {
        path: 'index',
        component: Index,
        meta: {
          title: '抖文科技 后台管理页面'
        }
      },
      {
        path: 'addart',
        component: AddArt,
        meta: {
          title: '新增文章'
        }
      },
      {
        path: 'addart/:id',
        component: AddArt,
        meta: {
          title: '编辑文章'
        },
        props: true
      },
      {
        path: 'artlist',
        component: ArtList,
        meta: {
          title: '文章列表'
        }
      },
      {
        path: 'addmoment',
        component: AddMoment,
        meta: {
          title: '新增动态'
        }
      },
      {
        path: 'addmoment/:id',
        component: AddMoment,
        meta: {
          title: '编辑动态'
        },
        props: true
      },
      {
        path: 'momentlist',
        component: MomentList,
        meta: {
          title: '动态列表'
        }
      },
      {
        path: 'channellist',
        component: ChannelList,
        meta: {
          title: '频道列表'
        }
      },
      {
        path: 'catelist',
        component: CateList,
        meta: {
          title: '分类列表'
        }
      },
      {
        path: 'userlist',
        component: UserList,
        meta: {
          title: '用户列表'
        }
      },
      {
        path: 'profile',
        component: Profile,
        meta: {
          title: '个人设置'
        }
      },
      {
        path: 'commentlist',
        component: CommentList,
        meta: {
          title: '评论管理'
        }
      },
      {
        path: 'addQuestion',
        component: AddQuestion,
        meta: {
          title: '新增问题'
        }
      },
      {
        path: 'question/detail/:id',
        component: QuestionDetail,
        meta: {
          title: '问题预览'
        },
        props: true
      },
      {
        path: 'addQuestion/:id',
        component: AddQuestion,
        meta: {
          title: '编辑问题'
        },
        props: true
      },
      {
        path: 'questionlist',
        component: QuestionList,
        meta: {
          title: '问题列表'
        }
      },
      {
        path: 'uploadpicture',
        component: UploadPicture,
        meta: {
          title: '上传图片'
        }
      },
      {
        path: 'settinglist',
        component: SettingList,
        meta: {
          title: '设置管理'
        }
      },
      {
        path: 'feedbacklist',
        component: FeedbackList,
        meta: {
          title: '用户反馈'
        }
      },
      {
        path: 'productlist',
        component: ProductList,
        meta: {
          title: '商品管理'
        }
      },
      {
        path: 'orderlist',
        component: OrderList,
        meta: {
          title: '订单管理'
        }
      },
      {
        path: 'topiclist',
        component: TopicList,
        meta: {
          title: '话题管理'
        }
      },
      {
        path: 'messagelist',
        component: MessageList,
        meta: {
          title: '消息管理'
        }
      },
      {
        path: 'reportlist',
        component: ReportList,
        meta: {
          title: '用户举报'
        }
      },
      {
        path: 'momentauditlist',
        component: MomentAuditList,
        meta: {
          title: '动态审核列表'
        }
      },
      {
        path: 'userauditlist',
        component: UserAuditList,
        meta: {
          title: '用户审核列表'
        }
      },
      {
        path: 'notificationlist',
        component: NotificationList,
        meta: {
          title: '通知管理'
        }
      }
    ]
  }
]

const router = new VueRouter({
  mode: 'history', // 去掉url中的#
  routes
})

router.beforeEach((to, from, next) => {

  if (to.meta.title) {
    document.title = to.meta.title
  }
  next()

  const userToken = window.sessionStorage.getItem('token')
  if (to.path === '/login') return next()
  if (!userToken) {
    next('/login')
  } else {
    next()
  }
})

export default router
