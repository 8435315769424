<template>
  <div>
    <a-card>
      <a-row :gutter="20">
        <a-col :span="6">
          <a-input-search v-model="queryParam.title" placeholder="输入话题名查找" enter-button allowClear @search="searchTopic"/>
        </a-col>
        <a-col :span="4">
          <a-button type="primary" @click="$router.push('/addQuestion')">新增</a-button>
        </a-col>

        <a-col :span="3">
          <a-select placeholder="请选择分类" style="width: 200px" @change="CateChange">
            <a-select-option
              v-for="item in Catelist"
              :key="item.id"
              :value="item.id"
            >{{ item.name }}</a-select-option>
          </a-select>
        </a-col>
        <a-col :span="1">
          <a-button type="info" @click="getQuestionList()">显示全部</a-button>
        </a-col>
      </a-row>

      <a-table
        rowKey="id"
        :columns="columns"
        :pagination="pagination"
        :dataSource="Questionlist"
        size="small"
        bordered
        @change="handleTableChange"
      >
        <template slot="level_text" slot-scope="lid">
          <div>{{stateJudgment(lid)}}</div>
        </template>
        <template slot="action" slot-scope="data">
          <div class="actionSlot">
            <a-button
              size="small"
              type="primary"
              icon="edit"
              style="margin-right: 15px"
              @click="$router.push(`/question/detail/${data.id}`)"
            >预览</a-button>
            <a-button
              size="small"
              type="primary"
              icon="edit"
              style="margin-right: 15px"
              @click="$router.push(`/addQuestion/${data.id}`)"
            >编辑</a-button>
            <a-button
              size="small"
              type="danger"
              icon="delete"
              style="margin-right: 15px"
              @click="deleteQuestion(data.id)"
            >删除</a-button>
          </div>
        </template>
      </a-table>
    </a-card>
  </div>
</template>

<script>
import day from 'dayjs'

const columns = [
  {
    title: 'id',
    dataIndex: 'id',
    width: '5%',
    key: 'id',
    align: 'center',
  },
  {
    title: '名称',
    dataIndex: 'name',
    width: '15%',
    key: 'name',
    align: 'center',
  },
  {
    title: '分类ID',
    dataIndex: 'cid',
    width: '5%',
    key: 'cid',
    align: 'center',
  },
  {
    title: 'url',
    dataIndex: 'url',
    width: '10%',
    key: 'url',
    align: 'center',
  },
  {
    title: 'cover',
    dataIndex: 'cover',
    width: '10%',
    key: 'cover',
    align: 'center',
  },
  // {
  //   title: '难度',
  //   dataIndex: 'level',
  //   width: '5%',
  //   key: 'level',
  //   align: 'center',
  //   scopedSlots: { customRender: 'level_text' },
  // },
  {
    title: '分类描述',
    dataIndex: 'desc',
    width: '20%',
    key: 'desc',
    align: 'center',
  },
  {
    title: '操作',
    width: '15%',
    key: 'action',
    align: 'center',
    scopedSlots: { customRender: 'action' },
  },
]



export default {
  data() {
    return {
      pagination: {
        pageSizeOptions: ['20', '50', '100'],
        pageSize: 100,
        total: 0,
        showSizeChanger: true,
        showTotal: (total) => `共${total}条`,
      },
      Questionlist: [],
      Catelist: [],
      columns,
      queryParam: {
        title: '',
        pagesize: 100,
        pagenum: 0,
      },
      Levellist: {
          '1': '简单',
          '2': '中等',
          '3': '困难'
      }
    }
  },
  created() {
    this.getQuestionList()
    this.getCateList()
  },
  methods: {
    stateJudgment(i){
      switch(i){
          case 1 : return "简单";
          break
          case 2 : return "中等";
          break
          case 3 : return "困难";
          break
      }
    },
        // 获取分类列表
    async searchTopic() {
      const { data: res } = await this.$http.get('topic/search', {
        params: {
          text: this.queryParam.title,
          pagesize: this.queryParam.pagesize,
          pagenum: this.queryParam.pagenum,
        },
      })
      // if (res.status !== 200) {
      //   if (res.status === 1004 || 1005 || 1006 || 1007) {
      //     window.sessionStorage.clear()
      //     this.$router.push('/login')
      //   }
      //   this.$message.error(res.message)
      // }

      this.Questionlist = res.data
      this.pagination.total = res.total
    },
    // 获取分类列表
    async getQuestionList() {
      const { data: res } = await this.$http.get('admin/topic', {
        params: {
          title: this.queryParam.title,
          pagesize: this.queryParam.pagesize,
          pagenum: this.queryParam.pagenum,
        },
      })
      this.Questionlist = res.data
      this.pagination.total = res.total
    },
    // 获取分类
    async getCateList() {
      const { data: res } = await this.$http.get('admin/category')
      if (res.status !== 200) return this.$message.error(res.message)
      this.Catelist = res.data
      this.pagination.total = res.total
    },
    // 更改分页
    handleTableChange(pagination, filters, sorter) {
      var pager = { ...this.pagination }
      pager.current = pagination.current
      pager.pageSize = pagination.pageSize
      this.queryParam.pagesize = pagination.pageSize
      this.queryParam.pagenum = pagination.current

      if (pagination.pageSize !== this.pagination.pageSize) {
        this.queryParam.pagenum = 0
        pager.current = 1
      }
      this.pagination = pager
      this.getQuestionList()
    },
    // 删除分类
    deleteQuestion(id) {
      this.$confirm({
        title: '提示：请再次确认',
        content: '确定要删除该分类吗？一旦删除，无法恢复',
        onOk: async () => {
          const { data: res } = await this.$http.post(`topic/delete/${id}`)
          if (res.status != 200) return this.$message.error(res.message)
          this.$message.success('删除成功')
          this.getQuestionList()
        },
        onCancel: () => {
          this.$message.info('已取消删除')
        },
      })
    },
    // 查询分类下的分类
    CateChange(value) {
      this.getCateQuestion(value)
    },
    async getCateQuestion(id) {
      this.queryParam.pagenum = 0
      const { data: res } = await this.$http.get(`topic/list`, {
        params: { pagesize: this.queryParam.pagesize, 
                  pagenum: this.queryParam.pagenum,
                  cid: id},
      })
      if (res.status !== 200) return this.$message.error(res.message)
      this.Questionlist = res.data
      this.pagination.total = res.total
    },
  },
}
</script>

<style scoped>
.actionSlot {
  display: flex;
  justify-content: center;
}
.QuestionImg {
  height: 100%;
  width: 100%;
}

.QuestionImg img {
  width: 100px;
  height: 80px;
}
</style>
