<template>
    <div>
      <a-card>
        <h3>{{ '分类预览' }}</h3>
  
        <a-form-model :model="questInfo" ref="questInfoRef" :rules="questInfoRules"
          :hideRequiredMark="true">
          <a-row :gutter="24">
            <a-col :span="16">
              <a-form-model-item label="分类标题" prop="title">
                <a-input style="width: 300px" v-model="questInfo.title"></a-input>
              </a-form-model-item>
              <a-form-model-item label="分类描述" prop="desc">
                <a-input type="textarea" v-model="questInfo.desc"></a-input>
              </a-form-model-item>
              <a-form-model-item label="解题思路" prop="think">
                <a-input type="textarea" v-model="questInfo.think"></a-input>
              </a-form-model-item>
              <a-form-model-item label="解题代码" prop="code">
                <pre v-highlightjs="questInfo.code"><code></code></pre>
              </a-form-model-item>
              <a-form-model-item label="LeetCode原题链接" prop="leetcode_url">
                <a-input type="textarea" v-model="questInfo.leetcode_url"></a-input>
              </a-form-model-item>
              <a-form-model-item label="大牛最佳题解" prop="best_answer_url">
                <a-input type="textarea" v-model="questInfo.best_answer_url"></a-input>
              </a-form-model-item>
              <a-form-model-item label="本站题解" prop="answer_url">
                <a-input type="textarea" v-model="questInfo.answer_url"></a-input>
              </a-form-model-item>
            </a-col>
            <a-col :span="8">
              <a-form-model-item label="分类分类" prop="cid">
                <a-select style="width: 200px" v-model="questInfo.cid" placeholder="请选择分类"
                  @change="cateChange">
                  <a-select-option v-for="item in Catelist" :key="item.id" :value="item.id">
                    {{
                        item.name
                    }}
                  </a-select-option>
                </a-select>
              </a-form-model-item>
              <a-form-model-item label="分类难度" prop="level">
                <a-select style="width: 200px" v-model="questInfo.level" placeholder="请选择难度"
                  @change="levelChange">
                  <a-select-option v-for="item in Levellist" :key="item.id" :value="item.id">
                    {{
                        item.name
                    }}
                  </a-select-option>
                </a-select>
              </a-form-model-item>
            </a-col>
          </a-row>
        
  
          <a-form-model-item>
            <a-button type="danger" style="margin-right: 15px" @click="artOk(questInfo.id)">
              {{
                  questInfo.id ? '更新' : '提交'
              }}
            </a-button>
            <a-button type="primary" @click="addCancel">取消</a-button>
          </a-form-model-item>
        </a-form-model>
      </a-card>
    </div>
  </template>
  
  <script>
  import { Url } from '../../plugin/http'
  export default {
    props: ['id'],
    data() {
      return {
        questInfo: {
          id: 0,
          title: '',
          cid: undefined,
          level: undefined,
          code: '',
          desc: '',
          think: '',
          leetcode_url: '',
          best_answer_url: '',
          answer_url: '',
        },
        levelInfo: {
            id: 1,
            name: '简单'
        },
        Catelist: [],
        Levellist: [
            {
                id: 1,
                name: '简单'
            },
            {
                id: 2,
                name: '中等'
            },
            {
                id: 3,
                name: '困难'
            }
        ],
        upUrl: Url + 'upload',
        headers: {},
        fileList: [],
        questInfoRules: {
          title: [{ required: true, message: '请输入分类标题', trigger: 'change' }],
          cid: [{ required: true, message: '请选择分类分类', trigger: 'change' }],
          level: [{ required: true, message: '请选择分类难度', trigger: 'change' }],
        },
      }
    },
    mounted() {
      this.getCateList()
      this.headers = { Authorization: `Bearer ${window.sessionStorage.getItem('token')}` }
      if (this.id) {
        this.getquestInfo(this.id)
      }
    },
    methods: {
      // 查询分类信息
      async getquestInfo(id) {
        const { data: res } = await this.$http.get(`admin/question/info/${id}`)
        if (res.status !== 200) {
          if (res.status === 1004 || 1005 || 1006 || 1007) {
            // window.sessionStorage.clear()
            this.$message.error(res.message)
            // this.$router.push('/login')
            return
          }
          this.$message.error(res.message)
          return
        }
        this.questInfo = res.data
        this.questInfo.id = res.data.id
      },
      // 获取分类列表
      async getCateList() {
        const { data: res } = await this.$http.get('admin/category')
        if (res.status !== 200) return this.$message.error(res.message)
        this.Catelist = res.data
      },
      // 选择分类
      cateChange(value) {
        this.questInfo.cid = value
      },
      // 选择难度
      levelChange(value) {
        this.questInfo.level = value
      },
      // 上传图片
      upChange(info) {
        if (info.file.status !== 'uploading') {
        }
        if (info.file.status === 'done') {
          this.$message.success(`图片上传成功`)
          this.questInfo.img = info.file.response.url
        } else if (info.file.status === 'error') {
          this.$message.error(`图片上传失败`)
        }
      },
      // 提交&&更新分类
      artOk(id) {
        this.$refs.questInfoRef.validate(async (valid) => {
          if (!valid) return this.$message.error('参数验证未通过，请按要求录入分类内容')
          if (id === 0) {
            const { data: res } = await this.$http.post('question/add', this.questInfo)
            if (res.status !== 200) return this.$message.error(res.message)
            this.$router.push('/questionlist')
            this.$message.success('添加分类成功')
          } else {
            const { data: res } = await this.$http.put(`question/${id}`, this.questInfo)
            if (res.status !== 200) return this.$message.error(res.message)
  
            this.$router.push('/questionlist')
            this.$message.success('更新分类成功')
          }
        })
      },
  
      addCancel() {
        this.$refs.questInfoRef.resetFields()
      },
    },
  }
  </script>