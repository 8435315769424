<template>
    <div>
      <a-card>
        <a-row :gutter="20">
          <a-col :span="4">
            <a-button type="primary" @click="addTimeRecordVisible = true">新增学习时长</a-button>
          </a-col>
        </a-row>
  
        <a-table
          rowKey="id"
          :columns="columns"
          :pagination="pagination"
          :dataSource="TimeRecordlist"
          size="small"
          bordered
          @change="handleTableChange"
        >
          <template slot="action" slot-scope="data">
            <div class="actionSlot">
              <a-button type="primary" icon="edit" style="margin-right: 15px" @click="editTimeRecord(data.id)">编辑</a-button>
              <a-button type="danger" icon="delete" style="margin-right: 15px" @click="deleteTimeRecord(data.id)"
                >删除</a-button
              >
            </div>
          </template>
        </a-table>
      </a-card>
  
      <!-- 新增学习时长区域 -->
      <a-modal
        closable
        title="新增学习时长"
        :visible="addTimeRecordVisible"
        width="60%"
        @ok="addTimeRecordOk"
        @cancel="addTimeRecordCancel"
        destroyOnClose
      >
        <a-form-model :model="newTimeRecord" :rules="addTimeRecordRules" ref="addTimeRecordRef">
          <a-form-model-item label="用户id" prop="uid">
            <a-input v-model="newTimeRecord.uid"></a-input>
          </a-form-model-item>
          <a-form-model-item label="学习天" prop="learn_day">
            <a-input v-model="newTimeRecord.learn_day"></a-input>
          </a-form-model-item>
          <a-form-model-item label="学习时长" prop="learn_time">
            <a-input v-model="newTimeRecord.learn_time"></a-input>
          </a-form-model-item>
        </a-form-model>
      </a-modal>
  
      <!-- 编辑学习时长区域 -->
      <a-modal
        closable
        destroyOnClose
        title="编辑学习时长"
        :visible="editTimeRecordVisible"
        width="60%"
        @ok="editTimeRecordOk"
        @cancel="editTimeRecordCancel"
      >
        <a-form-model :model="TimeRecordInfo" :rules="TimeRecordRules" ref="addTimeRecordRef">
          <a-form-model-item label="用户id" prop="uid">
            <a-input v-model="TimeRecordInfo.uid"></a-input>
          </a-form-model-item>
          <a-form-model-item label="学习天" prop="learn_day">
            <a-input v-model="TimeRecordInfo.learn_day"></a-input>
          </a-form-model-item>
          <a-form-model-item label="学习时长" prop="learn_time">
            <a-input v-model="TimeRecordInfo.learn_time"></a-input>
          </a-form-model-item>
        </a-form-model>
      </a-modal>
    </div>
  </template>
  
  <script>
  const columns = [
    {
      title: 'id',
      dataIndex: 'id',
      width: '5%',
      key: 'id',
      align: 'center',
    },
    {
      title: 'uid',
      dataIndex: 'uid',
      width: '20%',
      key: 'uid',
      align: 'center',
    },
    {
      title: '学习天',
      dataIndex: 'learn_day',
      width: '10%',
      key: 'learn_day',
      align: 'center',
    },
    {
      title: '学习时长',
      dataIndex: 'learn_time',
      width: '10%',
      key: 'learn_time',
      align: 'center',
    },
    {
      title: '学习时长描述',
      dataIndex: 'desc',
      width: '30%',
      key: 'desc',
      align: 'center',
    },
    {
      title: '操作',
      width: '15%',
      key: 'action',
      align: 'center',
      scopedSlots: { customRender: 'action' },
    },
  ]
  
  export default {
    data() {
      return {
        pagination: {
          pageSizeOptions: ['5', '10', '20'],
          pageSize: 5,
          total: 0,
          showSizeChanger: true,
          showTotal: (total) => `共${total}条`,
        },
        TimeRecordlist: [],
        TimeRecordInfo: {
          uid: '',  
          learn_day: 0,
          learn_time: 0,
          id: 0,
        },
        newTimeRecord: {
          uid: '',  
          learn_day: 0,
          learn_time: 0,
        },
        columns,
        queryParam: {
          pagesize: 5,
          pagenum: 1,
        },
        editVisible: false,
        TimeRecordRules: {
          sku: [
            {
              validator: (rule, value, callback) => {
                if (this.TimeRecordInfo.sku === '') {
                  callback(new Error('请输入学习时长sku'))
                  return
                }
                callback()
              },
              trigger: 'blur',
            },
          ],
          name: [
            {
              validator: (rule, value, callback) => {
                if (this.TimeRecordInfo.name === '') {
                  callback(new Error('请输入学习时长名'))
                  return
                }
                callback()
              },
              trigger: 'blur',
            },
          ],
          type: [
            {
              validator: (rule, value, callback) => {
                if (this.TimeRecordInfo.type === '') {
                  callback(new Error('请输入学习时长类型（0，string，1，json）'))
                  return
                }  
                callback()
              },
              trigger: 'blur',
            },
          ],
          price: [
            {
              validator: (rule, value, callback) => {
                if (this.TimeRecordInfo.price === '') {
                  callback(new Error('请输入学习时长封面value'))
                  return
                } 
                callback()
              },
              trigger: 'blur',
            },
          ],
          desc: [
            {
              validator: (rule, value, callback) => {
                if (this.TimeRecordInfo.desc === '') {
                  callback(new Error('请输入学习时长描述，英文名'))
                  return
                } 
                callback()
              },
              trigger: 'blur',
            },
          ]
        },
        addTimeRecordRules: {
           sku: [
            {
              validator: (rule, value, callback) => {
                if (this.newTimeRecord.sku === '') {
                  callback(new Error('请输入学习时长sku'))
                  return
                }
                callback()
              },
              trigger: 'blur',
            },
          ],
          name: [
            {
              validator: (rule, value, callback) => {
                if (this.newTimeRecord.name === '') {
                  callback(new Error('请输入学习时长名'))
                  return
                }
                callback()
              },
              trigger: 'blur',
            },
          ],
          type: [
            {
              validator: (rule, value, callback) => {
                if (this.newTimeRecord.type === '') {
                  callback(new Error('请输入学习时长类型（0，数据结构，1，算法）'))
                  return
                }  
                callback()
              },
              trigger: 'blur',
            },
          ],
          price: [
            {
              validator: (rule, value, callback) => {
                if (this.newTimeRecord.price === '') {
                  callback(new Error('请输入学习时长价格'))
                  return
                } 
                callback()
              },
              trigger: 'blur',
            },
          ],
          desc: [
            {
              validator: (rule, value, callback) => {
                if (this.newTimeRecord.desc === '') {
                  callback(new Error('请输入学习时长描述'))
                  return
                } 
                callback()
              },
              trigger: 'blur',
            },
          ]
        },
        editTimeRecordVisible: false,
        addTimeRecordVisible: false,
      }
    },
    created() {
      this.getTimeRecordList()
    },
    methods: {
      // 获取学习时长列表
      async getTimeRecordList() {
        const { data: res } = await this.$http.get('admin/timerecord', {
          pagesize: this.queryParam.pagesize,
          pagenum: this.queryParam.pagenum,
        })
  
        // if (res.status !== 200) {
        //   if (res.status === 1004 || 1005 || 1006 || 1007) {
        //     window.sessionStorage.clear()
        //     this.$router.push('/login')
        //   }
        //   this.$message.error(res.message)
        // }
        this.TimeRecordlist = res.data
        this.pagination.total = res.total
      },
      // 更改分页
      handleTableChange(pagination, filters, sorter) {
        var pager = { ...this.pagination }
        pager.current = pagination.current
        pager.pageSize = pagination.pageSize
        this.queryParam.pagesize = pagination.pageSize
        this.queryParam.pagenum = pagination.current
  
        if (pagination.pageSize !== this.pagination.pageSize) {
          this.queryParam.pagenum = 1
          pager.current = 1
        }
        this.pagination = pager
        this.getTimeRecordList()
      },
      // 删除学习时长
      deleteTimeRecord(id) {
        this.$confirm({
          title: '提示：请再次确认',
          content: '确定要删除该学习时长吗？一旦删除，无法恢复',
          onOk: async () => {
            const { data: res } = await this.$http.delete(`timerecord/${id}`)
            if (res.status != 200) return this.$message.error(res.message)
            this.$message.success('删除成功')
            this.getTimeRecordList()
          },
          onCancel: () => {
            this.$message.info('已取消删除')
          },
        })
      },
      // 新增学习时长
      addTimeRecordOk() {
        this.$refs.addTimeRecordRef.validate(async (valid) => {
          if (!valid) return this.$message.error('参数不符合要求，请重新输入')
          const { data: res } = await this.$http.post('timerecord/add', {
            uid: parseInt(this.newTimeRecord.uid),
            learn_day: parseInt(this.newTimeRecord.learn_day),
            learn_time:  parseInt(this.newTimeRecord.learn_time),
          })
          if (res.status != 200) return this.$message.error(res.message)
          this.$refs.addTimeRecordRef.resetFields()
          this.addTimeRecordVisible = false
          this.$message.success('添加学习时长成功')
          await this.getTimeRecordList()
        })
      },
      addTimeRecordCancel() {
        this.$refs.addTimeRecordRef.resetFields()
        this.addTimeRecordVisible = false
        this.$message.info('新增学习时长已取消')
      },
      // 编辑学习时长
      async editTimeRecord(id) {
        this.editTimeRecordVisible = true
        const { data: res } = await this.$http.get(`timerecord/${id}`)
        this.TimeRecordInfo = res.data
        this.TimeRecordInfo.id = id
      },
      editTimeRecordOk() {
        this.$refs.addTimeRecordRef.validate(async (valid) => {
          if (!valid) return this.$message.error('参数不符合要求，请重新输入')
          const { data: res } = await this.$http.put(`timerecord/${this.TimeRecordInfo.id}`, {
            uid: parseInt(this.TimeRecordInfo.uid),
            learn_day: parseInt(this.TimeRecordInfo.learn_day),
            learn_time:  parseInt(this.TimeRecordInfo.learn_time),
          })
          if (res.status != 200) return this.$message.error(res.message)
          this.editTimeRecordVisible = false
          this.$message.success('更新学习时长信息成功')
          this.getTimeRecordList()
        })
      },
      editTimeRecordCancel() {
        this.$refs.addTimeRecordRef.resetFields()
        this.editTimeRecordVisible = false
        this.$message.info('编辑已取消')
      },
    },
  }
  </script>
  
  <style scoped>
  .actionSlot {
    display: flex;
    justify-content: center;
  }
  </style>