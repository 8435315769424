import Vue from 'vue'
import axios from 'axios'


// let Url = 'https://api.idouwen.com/api/v1/admin/'
let Url = process.env.VUE_APP_API_URL

axios.defaults.baseURL = Url

axios.interceptors.request.use(config => {
  config.headers.Authorization = `Bearer ${window.sessionStorage.getItem('token')}`
  config.headers.uid=window.sessionStorage.getItem('uid')
  return config
})

Vue.prototype.$http = axios

export { Url }
