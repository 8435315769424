<template>
    <div>
      <a-card>
        <a-row :gutter="20">
          <a-col :span="4">
            <a-button type="primary" @click="addReportVisible = true">新增用户举报</a-button>
          </a-col>
        </a-row>

        <a-table
          rowKey="id"
          :columns="columns"
          :pagination="pagination"
          :dataSource="Reportlist"
          size="small"
          bordered
          @change="handleTableChange"
        >
          <template slot="action" slot-scope="data">
            <div class="actionSlot">
              <a-button type="primary" icon="edit" style="margin-right: 15px" @click="editReport(data.id)">编辑</a-button>
              <a-button type="danger" icon="delete" style="margin-right: 15px" @click="deleteReport(data.id)"
                >删除</a-button
              >
            </div>
          </template>
        </a-table>
      </a-card>

      <!-- 新增用户举报区域 -->
      <a-modal
        closable
        title="新增用户举报"
        :visible="addReportVisible"
        width="60%"
        @ok="addReportOk"
        @cancel="addReportCancel"
        destroyOnClose
      >
        <a-form-model :model="newReport" :rules="addReportRules" ref="addReportRef">
          <a-form-model-item label="用户举报描述" prop="desc">
            <a-input type="textarea" v-model="newReport.desc"></a-input>
          </a-form-model-item>
        </a-form-model>
      </a-modal>

      <!-- 编辑用户举报区域 -->
      <a-modal
        closable
        destroyOnClose
        title="编辑用户举报"
        :visible="editReportVisible"
        width="60%"
        @ok="editReportOk"
        @cancel="editReportCancel"
      >
        <a-form-model :model="ReportInfo" :rules="ReportRules" ref="addReportRef">
          <a-form-model-item label="用户举报描述" prop="desc">
            <a-input type="textarea" v-model="ReportInfo.desc"></a-input>
          </a-form-model-item>
        </a-form-model>
      </a-modal>
    </div>
  </template>

  <script>
  const columns = [
    {
      title: 'id',
      dataIndex: 'id',
      width: '5%',
      key: 'id',
      align: 'left',
    },
    {
      title: 'uid',
      dataIndex: 'uid',
      width: '5%',
      key: 'uid',
      align: 'left',
    },
    {
      title: 'src_id',
      dataIndex: 'src_id',
      width: '5%',
      key: 'src_id',
      align: 'left',
    },
    {
      title: 'src_type',
      dataIndex: 'src_type',
      width: '5%',
      key: 'src_type',
      align: 'left',
    },
    {
      title: 'created_at',
      dataIndex: 'created_at',
      width: '20%',
      key: 'created_at',
      align: 'left',
    },
    {
      title: 'reason',
      dataIndex: 'reason',
      width: '10%',
      key: 'reason',
      align: 'left',
    },
    {
      title: '用户举报描述',
      dataIndex: 'content',
      width: '40%',
      key: 'content',
      align: 'left',
    },
    {
      title: '操作',
      key: 'action',
      width: '30%',
      align: 'left',
      scopedSlots: { customRender: 'action' },
    },
  ]

  export default {
    data() {
      return {
        pagination: {
          pageSizeOptions: ['20', '50', '100'],
          pageSize: 20,
          total: 0,
          showSizeChanger: true,
          showTotal: (total) => `共${total}条`,
        },
        Reportlist: [],
        ReportInfo: {
          name: '',
          type: '',
          value: '',
          desc: '',
          id: 0,
        },
        newReport: {
          name: '',
          type: '',
          value: '',
          desc: '',
        },
        columns,
        queryParam: {
          pagesize: 5,
          pagenum: 1,
        },
        editVisible: false,
        ReportRules: {
          desc: [
            {
              validator: (rule, value, callback) => {
                if (this.ReportInfo.desc === '') {
                  callback(new Error('请输入用户举报描述，英文名'))
                  return
                }
                callback()
              },
              trigger: 'blur',
            },
          ]
        },
        addReportRules: {
          desc: [
            {
              validator: (rule, value, callback) => {
                if (this.newReport.desc === '') {
                  callback(new Error('请输入用户举报描述，英文名'))
                  return
                }
                callback()
              },
              trigger: 'blur',
            },
          ]
        },
        editReportVisible: false,
        addReportVisible: false,
      }
    },
    created() {
      this.getReportList()
    },
    methods: {
      // 获取用户举报列表
      async getReportList() {
        const { data: res } = await this.$http.get('reports', {
          pagesize: this.queryParam.pagesize,
          pagenum: this.queryParam.pagenum,
        })

        // if (res.status !== 200) {
        //   if (res.status === 1004 || 1005 || 1006 || 1007) {
        //     window.sessionStorage.clear()
        //     this.$router.push('/login')
        //   }
        //   this.$message.error(res.message)
        // }
        this.Reportlist = res.data
        this.pagination.total = res.total
      },
      // 更改分页
      handleTableChange(pagination, filters, sorter) {
        var pager = { ...this.pagination }
        pager.current = pagination.current
        pager.pageSize = pagination.pageSize
        this.queryParam.pagesize = pagination.pageSize
        this.queryParam.pagenum = pagination.current

        if (pagination.pageSize !== this.pagination.pageSize) {
          this.queryParam.pagenum = 1
          pager.current = 1
        }
        this.pagination = pager
        this.getReportList()
      },
      // 删除用户举报
      deleteReport(id) {
        this.$confirm({
          title: '提示：请再次确认',
          content: '确定要删除该用户举报吗？一旦删除，无法恢复',
          onOk: async () => {
            const { data: res } = await this.$http.post(`report/delete/${id}`)
            if (res.status != 200) return this.$message.error(res.message)
            this.$message.success('删除成功')
            this.getReportList()
          },
          onCancel: () => {
            this.$message.info('已取消删除')
          },
        })
      },
      // 新增用户举报
      addReportOk() {
        this.$refs.addReportRef.validate(async (valid) => {
          if (!valid) return this.$message.error('参数不符合要求，请重新输入')
          const { data: res } = await this.$http.post('report/add', {
            name: this.newReport.name,
            desc: this.newReport.desc,
            type: this.newReport.type,
            value:  this.newReport.value,
          })
          if (res.status != 200) return this.$message.error(res.message)
          this.$refs.addReportRef.resetFields()
          this.addReportVisible = false
          this.$message.success('添加用户举报成功')
          await this.getReportList()
        })
      },
      addReportCancel() {
        this.$refs.addReportRef.resetFields()
        this.addReportVisible = false
        this.$message.info('新增用户举报已取消')
      },
      // 编辑用户举报
      async editReport(id) {
        this.editReportVisible = true
        const { data: res } = await this.$http.get(`report/${id}`)
        this.ReportInfo = res.data
        this.ReportInfo.id = id
      },
      editReportOk() {
        this.$refs.addReportRef.validate(async (valid) => {
          if (!valid) return this.$message.error('参数不符合要求，请重新输入')
          const { data: res } = await this.$http.post(`report/edit/${this.ReportInfo.id}`, {
            name: this.ReportInfo.name,
            desc: this.ReportInfo.desc,
            type: parseInt(this.ReportInfo.type),
            value:  this.ReportInfo.value,
          })
          if (res.status != 200) return this.$message.error(res.message)
          this.editReportVisible = false
          this.$message.success('更新用户举报信息成功')
          this.getReportList()
        })
      },
      editReportCancel() {
        this.$refs.addReportRef.resetFields()
        this.editReportVisible = false
        this.$message.info('编辑已取消')
      },
    },
  }
  </script>

  <style scoped>
  .actionSlot {
    display: flex;
    justify-content: center;
  }
  </style>
