<template >
  <a-layout-sider breakpoint="lg" v-model="collapsed">
    <div class="log">
      <span>{{ collapsed ? 'Blog' : '抖文科技' }}</span>
    </div>

    <a-menu theme="dark" mode="inline" @click="goToPage">
      <a-menu-item key="index">
        <a-icon type="dashboard"></a-icon>
        <span>仪表盘</span>
      </a-menu-item>
     <a-sub-menu>
       <span slot="title">
         <a-icon type="file" />
         <span>话题管理</span>
       </span>
       <a-menu-item key="addQuestion">
         <a-icon type="form" />
         <span>新增话题</span>
       </a-menu-item>
       <a-menu-item key="questionlist">
         <a-icon type="snippets" />
         <span>话题列表</span>
       </a-menu-item>
       <a-menu-item key="catelist">
        <a-icon type="book" />
        <span>分类列表</span>
      </a-menu-item>
    </a-sub-menu>

     <a-sub-menu>
       <span slot="title">
         <a-icon type="file" />
         <span>文章管理</span>
       </span>
       <a-menu-item key="addart">
         <a-icon type="form" />
         <span>写文章</span>
       </a-menu-item>
       <a-menu-item key="artlist">
         <a-icon type="snippets" />
         <span>文章列表</span>
       </a-menu-item>
     </a-sub-menu>

      <a-sub-menu>
        <span slot="title">
          <a-icon type="file" />
          <span>动态管理</span>
        </span>
        <a-menu-item key="addmoment">
          <a-icon type="form" />
          <span>发动态</span>
        </a-menu-item>
        <a-menu-item key="momentlist">
          <a-icon type="snippets" />
          <span>动态列表</span>
        </a-menu-item>
        <a-menu-item key="commentlist">
          <a-icon type="message" />
          <span>评论管理</span>
        </a-menu-item>
      </a-sub-menu>


      <a-sub-menu>
        <span slot="title">
          <a-icon type="file" />
          <span>用户管理</span>
        </span>
        <a-menu-item key="userlist">
          <a-icon type="user" />
          <span>用户列表</span>
        </a-menu-item>
        <a-menu-item key="feedbacklist">
          <a-icon type="book" />
          <span>用户反馈</span>
        </a-menu-item>
        <a-menu-item key="reportlist">
          <a-icon type="book" />
          <span>用户举报</span>
        </a-menu-item>
      </a-sub-menu>

      <a-sub-menu>
        <span slot="title">
          <a-icon type="file" />
          <span>审核管理</span>
        </span>
        <a-menu-item key="momentauditlist">
          <a-icon type="user" />
          <span>动态审核列表</span>
        </a-menu-item>
        <a-menu-item key="userauditlist">
          <a-icon type="book" />
          <span>用户审核反馈</span>
        </a-menu-item>
      </a-sub-menu>

      <a-menu-item key="channellist">
        <a-icon type="book" />
        <span>频道列表</span>
      </a-menu-item>

      <a-menu-item key="productlist">
        <a-icon type="book" />
        <span>商品列表</span>
      </a-menu-item>

      <a-menu-item key="orderlist">
        <a-icon type="book" />
        <span>订单列表</span>
      </a-menu-item>

      <a-menu-item key="notificationlist">
        <a-icon type="book" />
        <span>通知管理</span>
      </a-menu-item>

      <a-menu-item key="messagelist">
        <a-icon type="book" />
        <span>消息管理</span>
      </a-menu-item>

      <a-menu-item key="uploadpicture">
        <a-icon type="snippets" />
        <span>上传图片</span>
      </a-menu-item>

      <a-menu-item key="settinglist">
        <a-icon type="book" />
        <span>设置管理</span>
      </a-menu-item>



<!--      <a-menu-item key="profile">-->
<!--        <a-icon type="setting" />-->
<!--        <span>个人设置</span>-->
<!--      </a-menu-item>-->
    </a-menu>
  </a-layout-sider>
</template>

<script>
export default {
  data() {
    return {
      collapsed: true,
    }
  },
  methods: {
    goToPage(item) {
      this.$router.push('/' + item.key).catch((err) => err)
    },
  },
}
</script>

<style scoped>
.log {
  height: 24px;
  margin: 16px;
  background-color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 17px;
}
</style>
