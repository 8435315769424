<template>
    <div>
      <a-card>
        <a-row :gutter="20">
          <a-col :span="4">
            <a-button type="primary" @click="addSettingVisible = true">新增设置</a-button>
          </a-col>
        </a-row>
  
        <a-table
          rowKey="id"
          :columns="columns"
          :pagination="pagination"
          :dataSource="Settinglist"
          size="small"
          bordered
          @change="handleTableChange"
        >
          <template slot="action" slot-scope="data">
            <div class="actionSlot">
              <a-button size="small" type="primary" icon="edit" style="margin-right: 15px" @click="editSetting(data.id)">编辑</a-button>
              <a-button size="small" type="danger" icon="delete" style="margin-right: 15px" @click="deleteSetting(data.id)">删除</a-button>
            </div>
          </template>
        </a-table>
      </a-card>
  
      <!-- 新增设置区域 -->
      <a-modal
        closable
        title="新增设置"
        :visible="addSettingVisible"
        width="60%"
        @ok="addSettingOk"
        @cancel="addSettingCancel"
        destroyOnClose
      >
        <a-form-model :model="newSetting" :rules="addSettingRules" ref="addSettingRef">
          <a-form-model-item label="设置名称" prop="name">
            <a-input v-model="newSetting.name"></a-input>
          </a-form-model-item>

          <a-form-model-item label="设置类型" prop="type">
            <a-radio-group v-model="newSetting.type">
                <a-radio :value="'bool'">布尔</a-radio>  
                <a-radio :value="'int'">整形</a-radio>
                <a-radio :value="'string'">字符串</a-radio>
                <a-radio :value="'json'">JSON</a-radio>
            </a-radio-group>
          </a-form-model-item>

          <a-form-model-item label="设置值" prop="value">
            <a-textarea style="height: 200px" v-model="newSetting.value"></a-textarea>
          </a-form-model-item>

          <a-form-model-item label="设置描述" prop="desc">
            <a-input v-model="newSetting.desc"></a-input>
          </a-form-model-item>
        </a-form-model>
      </a-modal>
  
      <!-- 编辑设置区域 -->
      <a-modal
        closable
        destroyOnClose
        title="编辑设置"
        :visible="editSettingVisible"
        width="60%"
        @ok="editSettingOk"
        @cancel="editSettingCancel"
      >
        <a-form-model :model="SettingInfo" :rules="SettingRules" ref="addSettingRef">
          <a-form-model-item label="设置名称" prop="name">
            <a-input v-model="SettingInfo.name"></a-input>
          </a-form-model-item>
          <a-form-model-item label="设置类型" prop="type">
            <a-radio-group v-model="SettingInfo.type">
                <a-radio :value="'bool'">布尔</a-radio>  
                <a-radio :value="'int'">整形</a-radio>
                <a-radio :value="'string'">字符串</a-radio>
                <a-radio :value="'json'">JSON</a-radio>
            </a-radio-group>
          </a-form-model-item>
          <a-form-model-item label="设置值" prop="value">
            <a-textarea style="height: 200px" v-model="SettingInfo.value"></a-textarea>
          </a-form-model-item>
          <a-form-model-item label="设置描述" prop="desc">
            <a-input v-model="SettingInfo.desc"></a-input>
          </a-form-model-item>
        </a-form-model>
      </a-modal>
    </div>
  </template>
  
<script>
  const columns = [
    {
      title: 'id',
      dataIndex: 'id',
      width: '5%',
      key: 'id',
      align: 'center',
    },
    {
      title: '设置名',
      dataIndex: 'name',
      width: '20%',
      key: 'name',
      align: 'center',
    },
    {
      title: '设置值',
      dataIndex: 'value',
      width: '30%',
      key: 'value',
      align: 'center',
    },
    {
      title: '设置类型',
      dataIndex: 'type',
      width: '5%',
      key: 'type',
      align: 'center',
    },
    {
      title: '设置描述',
      dataIndex: 'desc',
      width: '30%',
      key: 'desc',
      align: 'center',
    },
    {
      title: '操作',
      width: '15%',
      key: 'action',
      align: 'center',
      scopedSlots: { customRender: 'action' },
    },
  ]
  
  export default {
    data() {
      return {
        pagination: {
          pageSizeOptions: ['20', '50', '100'],
          pageSize: 20,
          total: 0,
          showSizeChanger: true,
          showTotal: (total) => `共${total}条`,
        },
        Settinglist: [],
        SettingInfo: {
          name: '',
          type: '',
          value: '',
          desc: '',
          id: 0,
        },
        newSetting: {
          name: '',
          type: '',
          value: '',
          desc: '',
        },
        columns,
        queryParam: {
          pagesize: 20,
          pagenum: 1,
        },
        editVisible: false,
        SettingRules: {
          name: [
            {
              validator: (rule, value, callback) => {
                if (this.SettingInfo.name === '') {
                  callback(new Error('请输入设置名'))
                  return
                }
                callback()
              },
              trigger: 'blur',
            },
          ],
          type: [
            {
              validator: (rule, value, callback) => {
                if (this.SettingInfo.type === '') {
                  callback(new Error('请输入设置类型（0，string，1，json）'))
                  return
                }  
                callback()
              },
              trigger: 'blur',
            },
          ],
          value: [
            {
              validator: (rule, value, callback) => {
                if (this.SettingInfo.value === '') {
                  callback(new Error('请输入设置封面value'))
                  return
                } 
                callback()
              },
              trigger: 'blur',
            },
          ],
          desc: [
            {
              validator: (rule, value, callback) => {
                if (this.SettingInfo.desc === '') {
                  callback(new Error('请输入设置描述，英文名'))
                  return
                } 
                callback()
              },
              trigger: 'blur',
            },
          ]
        },
        addSettingRules: {
          name: [
            {
              validator: (rule, value, callback) => {
                if (this.newSetting.name === '') {
                  callback(new Error('请输入设置名'))
                  return
                }
                callback()
              },
              trigger: 'blur',
            },
          ],
          type: [
            {
              validator: (rule, value, callback) => {
                if (this.newSetting.type === '') {
                  callback(new Error('请输入设置类型（0，数据结构，1，算法）'))
                  return
                }  
                callback()
              },
              trigger: 'blur',
            },
          ],
          value: [
            {
              validator: (rule, value, callback) => {
                if (this.newSetting.value === '') {
                  callback(new Error('请输入设置封面value'))
                  return
                } 
                callback()
              },
              trigger: 'blur',
            },
          ],
          desc: [
            {
              validator: (rule, value, callback) => {
                if (this.newSetting.desc === '') {
                  callback(new Error('请输入设置描述，英文名'))
                  return
                } 
                callback()
              },
              trigger: 'blur',
            },
          ]
        },
        editSettingVisible: false,
        addSettingVisible: false,
      }
    },
    created() {
      this.getSettingList()
    },
    methods: {
      // 获取设置列表
      async getSettingList() {
        const { data: res } = await this.$http.get('admin/setting', {
          pagesize: this.queryParam.pagesize,
          pagenum: this.queryParam.pagenum,
        })
  
        // if (res.status !== 200) {
        //   if (res.status === 1004 || 1005 || 1006 || 1007) {
        //     window.sessionStorage.clear()
        //     this.$router.push('/login')
        //   }
        //   this.$message.error(res.message)
        // }
        this.Settinglist = res.data
        this.pagination.total = res.total
      },
      // 更改分页
      handleTableChange(pagination, filters, sorter) {
        var pager = { ...this.pagination }
        pager.current = pagination.current
        pager.pageSize = pagination.pageSize
        this.queryParam.pagesize = pagination.pageSize
        this.queryParam.pagenum = pagination.current
  
        if (pagination.pageSize !== this.pagination.pageSize) {
          this.queryParam.pagenum = 1
          pager.current = 1
        }
        this.pagination = pager
        this.getSettingList()
      },
      // 删除设置
      deleteSetting(id) {
        this.$confirm({
          title: '提示：请再次确认',
          content: '确定要删除该设置吗？一旦删除，无法恢复',
          onOk: async () => {
            const { data: res } = await this.$http.delete(`setting/${id}`)
            if (res.status != 200) return this.$message.error(res.message)
            this.$message.success('删除成功')
            this.getSettingList()
          },
          onCancel: () => {
            this.$message.info('已取消删除')
          },
        })
      },
      // 新增设置
      addSettingOk() {
        this.$refs.addSettingRef.validate(async (valid) => {
          if (!valid) return this.$message.error('参数不符合要求，请重新输入')
          const { data: res } = await this.$http.post('setting/add', {
            name: this.newSetting.name,
            desc: this.newSetting.desc,
            type: this.newSetting.type,
            value:  this.newSetting.value,
          })
          if (res.status != 200) return this.$message.error(res.message)
          this.$refs.addSettingRef.resetFields()
          this.addSettingVisible = false
          this.$message.success('添加设置成功')
          await this.getSettingList()
        })
      },
      addSettingCancel() {
        this.$refs.addSettingRef.resetFields()
        this.addSettingVisible = false
        this.$message.info('新增设置已取消')
      },
      // 编辑设置
      async editSetting(id) {
        this.editSettingVisible = true
        const { data: res } = await this.$http.get(`setting/${id}`)
        this.SettingInfo = res.data
        this.SettingInfo.id = id
      },
      editSettingOk() {
        this.$refs.addSettingRef.validate(async (valid) => {
          if (!valid) return this.$message.error('参数不符合要求，请重新输入')
          const { data: res } = await this.$http.put(`setting/${this.SettingInfo.id}`, {
            name: this.SettingInfo.name,
            desc: this.SettingInfo.desc,
            type: this.SettingInfo.type,
            value:  this.SettingInfo.value,
          })
          if (res.status != 200) return this.$message.error(res.message)
          this.editSettingVisible = false
          this.$message.success('更新设置信息成功')
          this.getSettingList()
        })
      },
      editSettingCancel() {
        this.$refs.addSettingRef.resetFields()
        this.editSettingVisible = false
        this.$message.info('编辑已取消')
      },
    },
  }
  </script>
  
  <style scoped>
  .actionSlot {
    display: flex;
    justify-content: center;
  }
  </style>