<template>
    <div>
      <a-card>
        <a-row :gutter="20">
          <a-col :span="4">
            <a-button type="primary" @click="addProductVisible = true">新增商品</a-button>
          </a-col>
        </a-row>

        <a-table
          rowKey="id"
          :columns="columns"
          :pagination="pagination"
          :dataSource="Productlist"
          bordered
          @change="handleTableChange"
        >
          <template slot="action" slot-scope="data">
            <div class="actionSlot">
              <a-button type="primary" icon="edit" style="margin-right: 15px" @click="editProduct(data.id)">编辑</a-button>
              <a-button type="danger" icon="delete" style="margin-right: 15px" @click="deleteProduct(data.id)"
                >删除</a-button
              >
            </div>
          </template>
        </a-table>
      </a-card>

      <!-- 新增商品区域 -->
      <a-modal
        closable
        title="新增商品"
        :visible="addProductVisible"
        width="60%"
        @ok="addProductOk"
        @cancel="addProductCancel"
        destroyOnClose
      >
        <a-form-model :model="newProduct" :rules="addProductRules" ref="addProductRef">
          <a-form-model-item label="sku" prop="sku">
            <a-input v-model="newProduct.sku"></a-input>
          </a-form-model-item>
          <a-form-model-item label="商品名称" prop="name">
            <a-input v-model="newProduct.name"></a-input>
          </a-form-model-item>
          <a-form-model-item label="价格" prop="price">
            <a-input v-model="newProduct.price"></a-input>
          </a-form-model-item>
          <a-form-model-item label="时长" prop="duration">
            <a-input v-model="newProduct.duration"></a-input>
          </a-form-model-item>
          <a-form-model-item label="商品类型(0：苹果，1：微信)" prop="type">
            <a-input v-model="newProduct.type"></a-input>
          </a-form-model-item>
          <a-form-model-item label="商品描述" prop="desc">
            <a-input v-model="newProduct.desc"></a-input>
          </a-form-model-item>
        </a-form-model>
      </a-modal>

      <!-- 编辑商品区域 -->
      <a-modal
        closable
        destroyOnClose
        title="编辑商品"
        :visible="editProductVisible"
        width="60%"
        @ok="editProductOk"
        @cancel="editProductCancel"
      >
        <a-form-model :model="ProductInfo" :rules="ProductRules" ref="addProductRef">
          <a-form-model-item label="sku" prop="sku">
            <a-input v-model="ProductInfo.sku"></a-input>
          </a-form-model-item>
          <a-form-model-item label="商品名称" prop="name">
            <a-input v-model="ProductInfo.name"></a-input>
          </a-form-model-item>
          <a-form-model-item label="价格" prop="price">
            <a-input v-model="ProductInfo.price"></a-input>
          </a-form-model-item>
          <a-form-model-item label="时长" prop="duration">
            <a-input v-model="ProductInfo.duration"></a-input>
          </a-form-model-item>
          <a-form-model-item label="商品类型(0：苹果，1：微信)" prop="type">
            <a-input v-model="ProductInfo.type"></a-input>
          </a-form-model-item>
          <a-form-model-item label="商品描述" prop="desc">
            <a-input v-model="ProductInfo.desc"></a-input>
          </a-form-model-item>
        </a-form-model>
      </a-modal>
    </div>
  </template>

  <script>
  const columns = [
    {
      title: 'id',
      dataIndex: 'id',
      width: '5%',
      key: 'id',
      align: 'center',
    },
    {
      title: 'sku',
      dataIndex: 'sku',
      width: '20%',
      key: 'sku',
      align: 'center',
    },
    {
      title: '商品名',
      dataIndex: 'name',
      width: '10%',
      key: 'name',
      align: 'center',
    },
    {
      title: '价格',
      dataIndex: 'price',
      width: '10%',
      key: 'price',
      align: 'center',
    },
    {
      title: '时长',
      dataIndex: 'duration',
      width: '10%',
      key: 'duration',
      align: 'center',
    },
    {
      title: '商品类型(0：苹果，1：微信)',
      dataIndex: 'type',
      width: '10%',
      key: 'type',
      align: 'center',
    },
    {
      title: '商品描述',
      dataIndex: 'desc',
      width: '30%',
      key: 'desc',
      align: 'center',
    },
    {
      title: '操作',
      width: '15%',
      key: 'action',
      align: 'center',
      scopedSlots: { customRender: 'action' },
    },
  ]

  export default {
    data() {
      return {
        pagination: {
          pageSizeOptions: ['5', '10', '20'],
          pageSize: 5,
          total: 0,
          showSizeChanger: true,
          showTotal: (total) => `共${total}条`,
        },
        Productlist: [],
        ProductInfo: {
          sku: '',
          name: '',
          type: '',
          value: '',
          desc: '',
          duration: '',
          id: 0,
        },
        newProduct: {
          sku: '',
          name: '',
          type: '',
          value: '',
          desc: '',
          duration: '',
        },
        columns,
        queryParam: {
          pagesize: 10,
          pagenum: 0,
        },
        editVisible: false,
        ProductRules: {
          sku: [
            {
              validator: (rule, value, callback) => {
                if (this.ProductInfo.sku === '') {
                  callback(new Error('请输入商品sku'))
                  return
                }
                callback()
              },
              trigger: 'blur',
            },
          ],
          name: [
            {
              validator: (rule, value, callback) => {
                if (this.ProductInfo.name === '') {
                  callback(new Error('请输入商品名'))
                  return
                }
                callback()
              },
              trigger: 'blur',
            },
          ],
          type: [
            {
              validator: (rule, value, callback) => {
                if (this.ProductInfo.type === '') {
                  callback(new Error('请输入商品类型（0，string，1，json）'))
                  return
                }
                callback()
              },
              trigger: 'blur',
            },
          ],
          price: [
            {
              validator: (rule, value, callback) => {
                if (this.ProductInfo.price === '') {
                  callback(new Error('请输入商品封面value'))
                  return
                }
                callback()
              },
              trigger: 'blur',
            },
          ],
          desc: [
            {
              validator: (rule, value, callback) => {
                if (this.ProductInfo.desc === '') {
                  callback(new Error('请输入商品描述，英文名'))
                  return
                }
                callback()
              },
              trigger: 'blur',
            },
          ]
        },
        addProductRules: {
           sku: [
            {
              validator: (rule, value, callback) => {
                if (this.newProduct.sku === '') {
                  callback(new Error('请输入商品sku'))
                  return
                }
                callback()
              },
              trigger: 'blur',
            },
          ],
          name: [
            {
              validator: (rule, value, callback) => {
                if (this.newProduct.name === '') {
                  callback(new Error('请输入商品名'))
                  return
                }
                callback()
              },
              trigger: 'blur',
            },
          ],
          type: [
            {
              validator: (rule, value, callback) => {
                if (this.newProduct.type === '') {
                  callback(new Error('请输入商品类型（0，string，1，json）'))
                  return
                }
                callback()
              },
              trigger: 'blur',
            },
          ],
          price: [
            {
              validator: (rule, value, callback) => {
                if (this.newProduct.price === '') {
                  callback(new Error('请输入商品价格'))
                  return
                }
                callback()
              },
              trigger: 'blur',
            },
          ],
          desc: [
            {
              validator: (rule, value, callback) => {
                if (this.newProduct.desc === '') {
                  callback(new Error('请输入商品描述'))
                  return
                }
                callback()
              },
              trigger: 'blur',
            },
          ]
        },
        editProductVisible: false,
        addProductVisible: false,
      }
    },
    created() {
      this.getProductList()
    },
    methods: {
      // 获取商品列表
      async getProductList() {
        const { data: res } = await this.$http.get('/products/new', {
          pagesize: this.queryParam.pagesize,
          pagenum: this.queryParam.pagenum,
        })

        // if (res.status !== 200) {
        //   if (res.status === 1004 || 1005 || 1006 || 1007) {
        //     window.sessionStorage.clear()
        //     this.$router.push('/login')
        //   }
        //   this.$message.error(res.message)
        // }
        this.Productlist = res.data
        this.pagination.total = res.total
      },
      // 更改分页
      handleTableChange(pagination, filters, sorter) {
        var pager = { ...this.pagination }
        pager.current = pagination.current
        pager.pageSize = pagination.pageSize
        this.queryParam.pagesize = pagination.pageSize
        this.queryParam.pagenum = pagination.current

        if (pagination.pageSize !== this.pagination.pageSize) {
          this.queryParam.pagenum = 1
          pager.current = 1
        }
        this.pagination = pager
        this.getProductList()
      },
      // 删除商品
      deleteProduct(id) {
        this.$confirm({
          title: '提示：请再次确认',
          content: '确定要删除该商品吗？一旦删除，无法恢复',
          onOk: async () => {
            const { data: res } = await this.$http.post(`product/delete/${id}`)
            if (res.status != 200) return this.$message.error(res.message)
            this.$message.success('删除成功')
            this.getProductList()
          },
          onCancel: () => {
            this.$message.info('已取消删除')
          },
        })
      },
      // 新增商品
      addProductOk() {
        this.$refs.addProductRef.validate(async (valid) => {
          if (!valid) return this.$message.error('参数不符合要求，请重新输入')
          const { data: res } = await this.$http.post('product/add', {
            sku: this.newProduct.sku,
            name: this.newProduct.name,
            desc: this.newProduct.desc,
            duration: parseInt(this.newProduct.duration),
            type: parseInt(this.newProduct.type),
            price:  parseInt(this.newProduct.price),
          })
          if (res.status != 200) return this.$message.error(res.message)
          this.$refs.addProductRef.resetFields()
          this.addProductVisible = false
          this.$message.success('添加商品成功')
          await this.getProductList()
        })
      },
      addProductCancel() {
        this.$refs.addProductRef.resetFields()
        this.addProductVisible = false
        this.$message.info('新增商品已取消')
      },
      // 编辑商品
      async editProduct(id) {
        this.editProductVisible = true
        const { data: res } = await this.$http.get(`product/${id}`)
        this.ProductInfo = res.data
        this.ProductInfo.id = id
      },
      editProductOk() {
        this.$refs.addProductRef.validate(async (valid) => {
          if (!valid) return this.$message.error('参数不符合要求，请重新输入')
          const { data: res } = await this.$http.post(`product/edit/${this.ProductInfo.id}`, {
            sku: this.ProductInfo.sku,
            name: this.ProductInfo.name,
            desc: this.ProductInfo.desc,
            duration: parseInt(this.ProductInfo.duration),
            type: parseInt(this.ProductInfo.type),
            price:  parseInt(this.ProductInfo.price),
          })
          if (res.status != 200) return this.$message.error(res.message)
          this.editProductVisible = false
          this.$message.success('更新商品信息成功')
          this.getProductList()
        })
      },
      editProductCancel() {
        this.$refs.addProductRef.resetFields()
        this.editProductVisible = false
        this.$message.info('编辑已取消')
      },
    },
  }
  </script>

  <style scoped>
  .actionSlot {
    display: flex;
    justify-content: center;
  }
  </style>
