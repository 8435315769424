<template>
    <div>
      <a-card>
        <a-row :gutter="20">
          <a-col :span="4">
            <a-button type="primary" @click="addTimeBillboardVisible = true">新增学习榜单</a-button>
          </a-col>
        </a-row>
  
        <a-table
          rowKey="id"
          :columns="columns"
          :pagination="pagination"
          :dataSource="TimeBillboardlist"
          size="small"
          bordered
          @change="handleTableChange"
        >
          <template slot="action" slot-scope="data">
            <div class="actionSlot">
              <a-button type="primary" icon="edit" style="margin-right: 15px" @click="editTimeBillboard(data.id)">编辑</a-button>
              <a-button type="danger" icon="delete" style="margin-right: 15px" @click="deleteTimeBillboard(data.id)"
                >删除</a-button
              >
            </div>
          </template>
        </a-table>
      </a-card>
  
      <!-- 新增学习榜单区域 -->
      <a-modal
        closable
        title="新增学习榜单"
        :visible="addTimeBillboardVisible"
        width="60%"
        @ok="addTimeBillboardOk"
        @cancel="addTimeBillboardCancel"
        destroyOnClose
      >
        <a-form-model :model="newTimeBillboard" :rules="addTimeBillboardRules" ref="addTimeBillboardRef">
            <a-form-model-item label="用户id" prop="uid">
            <a-input v-model="newTimeBillboard.uid"></a-input>
          </a-form-model-item>
          <a-form-model-item label="学习周" prop="learn_weak">
            <a-input v-model="newTimeBillboard.learn_weak"></a-input>
          </a-form-model-item>
          <a-form-model-item label="学习时长" prop="learn_time">
            <a-input v-model="newTimeBillboard.learn_time"></a-input>
          </a-form-model-item>
        </a-form-model>
      </a-modal>
  
      <!-- 编辑学习榜单区域 -->
      <a-modal
        closable
        destroyOnClose
        title="编辑学习榜单"
        :visible="editTimeBillboardVisible"
        width="60%"
        @ok="editTimeBillboardOk"
        @cancel="editTimeBillboardCancel"
      >
        <a-form-model :model="TimeBillboardInfo" :rules="TimeBillboardRules" ref="addTimeBillboardRef">
         <a-form-model-item label="用户id" prop="uid">
            <a-input v-model="TimeBillboardInfo.uid"></a-input>
          </a-form-model-item>
          <a-form-model-item label="学习周" prop="learn_weak">
            <a-input v-model="TimeBillboardInfo.learn_weak"></a-input>
          </a-form-model-item>
          <a-form-model-item label="学习时长" prop="learn_time">
            <a-input v-model="TimeBillboardInfo.learn_time"></a-input>
          </a-form-model-item>
        </a-form-model>
      </a-modal>
    </div>
  </template>
  
  <script>
  const columns = [
  {
      title: 'id',
      dataIndex: 'id',
      width: '5%',
      key: 'id',
      align: 'center',
    },
    {
      title: 'uid',
      dataIndex: 'uid',
      width: '20%',
      key: 'uid',
      align: 'center',
    },
    {
      title: '学习周',
      dataIndex: 'learn_weak',
      width: '10%',
      key: 'learn_weak',
      align: 'center',
    },
    {
      title: '学习时长',
      dataIndex: 'learn_time',
      width: '10%',
      key: 'learn_time',
      align: 'center',
    },
    {
      title: '操作',
      width: '15%',
      key: 'action',
      align: 'center',
      scopedSlots: { customRender: 'action' },
    },
  ]
  
  export default {
    data() {
      return {
        pagination: {
          pageSizeOptions: ['5', '10', '20'],
          pageSize: 5,
          total: 0,
          showSizeChanger: true,
          showTotal: (total) => `共${total}条`,
        },
        TimeBillboardlist: [],
        TimeBillboardInfo: {
          uid: 0,  
          learn_weak: 0,
          learn_time: 0,
          id: 0,
        },
        newTimeBillboard: {
          uid: 0,  
          learn_weak: 0,
          learn_time: 0,
        },
        columns,
        queryParam: {
          pagesize: 5,
          pagenum: 1,
        },
        editVisible: false,
        TimeBillboardRules: {
          sku: [
            {
              validator: (rule, value, callback) => {
                if (this.TimeBillboardInfo.sku === '') {
                  callback(new Error('请输入学习榜单sku'))
                  return
                }
                callback()
              },
              trigger: 'blur',
            },
          ],
          name: [
            {
              validator: (rule, value, callback) => {
                if (this.TimeBillboardInfo.name === '') {
                  callback(new Error('请输入学习榜单名'))
                  return
                }
                callback()
              },
              trigger: 'blur',
            },
          ],
          type: [
            {
              validator: (rule, value, callback) => {
                if (this.TimeBillboardInfo.type === '') {
                  callback(new Error('请输入学习榜单类型（0，string，1，json）'))
                  return
                }  
                callback()
              },
              trigger: 'blur',
            },
          ],
          price: [
            {
              validator: (rule, value, callback) => {
                if (this.TimeBillboardInfo.price === '') {
                  callback(new Error('请输入学习榜单封面value'))
                  return
                } 
                callback()
              },
              trigger: 'blur',
            },
          ],
          desc: [
            {
              validator: (rule, value, callback) => {
                if (this.TimeBillboardInfo.desc === '') {
                  callback(new Error('请输入学习榜单描述，英文名'))
                  return
                } 
                callback()
              },
              trigger: 'blur',
            },
          ]
        },
        addTimeBillboardRules: {
           sku: [
            {
              validator: (rule, value, callback) => {
                if (this.newTimeBillboard.sku === '') {
                  callback(new Error('请输入学习榜单sku'))
                  return
                }
                callback()
              },
              trigger: 'blur',
            },
          ],
          name: [
            {
              validator: (rule, value, callback) => {
                if (this.newTimeBillboard.name === '') {
                  callback(new Error('请输入学习榜单名'))
                  return
                }
                callback()
              },
              trigger: 'blur',
            },
          ],
          type: [
            {
              validator: (rule, value, callback) => {
                if (this.newTimeBillboard.type === '') {
                  callback(new Error('请输入学习榜单类型（0，数据结构，1，算法）'))
                  return
                }  
                callback()
              },
              trigger: 'blur',
            },
          ],
          price: [
            {
              validator: (rule, value, callback) => {
                if (this.newTimeBillboard.price === '') {
                  callback(new Error('请输入学习榜单价格'))
                  return
                } 
                callback()
              },
              trigger: 'blur',
            },
          ],
          desc: [
            {
              validator: (rule, value, callback) => {
                if (this.newTimeBillboard.desc === '') {
                  callback(new Error('请输入学习榜单描述'))
                  return
                } 
                callback()
              },
              trigger: 'blur',
            },
          ]
        },
        editTimeBillboardVisible: false,
        addTimeBillboardVisible: false,
      }
    },
    created() {
      this.getTimeBillboardList()
    },
    methods: {
      // 获取学习榜单列表
      async getTimeBillboardList() {
        const { data: res } = await this.$http.get('admin/timebillboard', {
          pagesize: this.queryParam.pagesize,
          pagenum: this.queryParam.pagenum,
        })
  
        // if (res.status !== 200) {
        //   if (res.status === 1004 || 1005 || 1006 || 1007) {
        //     window.sessionStorage.clear()
        //     this.$router.push('/login')
        //   }
        //   this.$message.error(res.message)
        // }
        this.TimeBillboardlist = res.data
        this.pagination.total = res.total
      },
      // 更改分页
      handleTableChange(pagination, filters, sorter) {
        var pager = { ...this.pagination }
        pager.current = pagination.current
        pager.pageSize = pagination.pageSize
        this.queryParam.pagesize = pagination.pageSize
        this.queryParam.pagenum = pagination.current
  
        if (pagination.pageSize !== this.pagination.pageSize) {
          this.queryParam.pagenum = 1
          pager.current = 1
        }
        this.pagination = pager
        this.getTimeBillboardList()
      },
      // 删除学习榜单
      deleteTimeBillboard(id) {
        this.$confirm({
          title: '提示：请再次确认',
          content: '确定要删除该学习榜单吗？一旦删除，无法恢复',
          onOk: async () => {
            const { data: res } = await this.$http.delete(`timebillboard/${id}`)
            if (res.status != 200) return this.$message.error(res.message)
            this.$message.success('删除成功')
            this.getTimeBillboardList()
          },
          onCancel: () => {
            this.$message.info('已取消删除')
          },
        })
      },
      // 新增学习榜单
      addTimeBillboardOk() {
        this.$refs.addTimeBillboardRef.validate(async (valid) => {
          if (!valid) return this.$message.error('参数不符合要求，请重新输入')
          const { data: res } = await this.$http.post('timebillboard/add', {
            uid: parseInt(this.newTimeBillboard.uid),
            learn_weak: parseInt(this.newTimeBillboard.learn_weak),
            learn_time:  parseInt(this.newTimeBillboard.learn_time),
          })
          if (res.status != 200) return this.$message.error(res.message)
          this.$refs.addTimeBillboardRef.resetFields()
          this.addTimeBillboardVisible = false
          this.$message.success('添加学习榜单成功')
          await this.getTimeBillboardList()
        })
      },
      addTimeBillboardCancel() {
        this.$refs.addTimeBillboardRef.resetFields()
        this.addTimeBillboardVisible = false
        this.$message.info('新增学习榜单已取消')
      },
      // 编辑学习榜单
      async editTimeBillboard(id) {
        this.editTimeBillboardVisible = true
        const { data: res } = await this.$http.get(`timebillboard/${id}`)
        this.TimeBillboardInfo = res.data
        this.TimeBillboardInfo.id = id
      },
      editTimeBillboardOk() {
        this.$refs.addTimeBillboardRef.validate(async (valid) => {
          if (!valid) return this.$message.error('参数不符合要求，请重新输入')
          const { data: res } = await this.$http.put(`timebillboard/${this.TimeBillboardInfo.id}`, {
            uid: parseInt(this.TimeBillboardInfo.uid),
            learn_weak: parseInt(this.TimeBillboardInfo.learn_weak),
            learn_time:  parseInt(this.TimeBillboardInfo.learn_time),
          })
          if (res.status != 200) return this.$message.error(res.message)
          this.editTimeBillboardVisible = false
          this.$message.success('更新学习榜单信息成功')
          this.getTimeBillboardList()
        })
      },
      editTimeBillboardCancel() {
        this.$refs.addTimeBillboardRef.resetFields()
        this.editTimeBillboardVisible = false
        this.$message.info('编辑已取消')
      },
    },
  }
  </script>
  
  <style scoped>
  .actionSlot {
    display: flex;
    justify-content: center;
  }
  </style>