<template>
    <div>
      <a-card>
        <a-row :gutter="20">
          <a-col :span="4">
            <a-button type="primary" @click="addMessageVisible = true">新增消息</a-button>
          </a-col>
        </a-row>

        <a-table
          rowKey="id"
          :columns="columns"
          :pagination="pagination"
          :dataSource="Messagelist"
          size="small"
          bordered
          @change="handleTableChange"
        >
          <template slot="action" slot-scope="data">
            <div class="actionSlot">
              <a-button size="small" type="primary" icon="edit" style="margin-right: 15px" @click="editMessage(data.id)">编辑</a-button>
              <a-button size="small" type="danger" icon="delete" style="margin-right: 15px" @click="deleteMessage(data.id)"
                >删除</a-button
              >
            </div>
          </template>
        </a-table>
      </a-card>

      <!-- 新增消息区域 -->
      <a-modal
        closable
        title="新增消息"
        :visible="addMessageVisible"
        width="60%"
        @ok="addMessageOk"
        @cancel="addMessageCancel"
        destroyOnClose
      >
        <a-form-model :model="newMessage" :rules="addMessageRules" ref="addMessageRef">
          <a-form-model-item label="消息内容" prop="content">
            <a-input v-model="newMessage.content"></a-input>
          </a-form-model-item>
          <a-form-model-item label="发送者id" prop="sender_id">
            <a-input v-model="newMessage.sender_id"></a-input>
          </a-form-model-item>
          <a-form-model-item label="接收者id" prop="target_id">
            <a-input v-model="newMessage.target_id"></a-input>
          </a-form-model-item>
        </a-form-model>
      </a-modal>

      <!-- 编辑消息区域 -->
      <a-modal
        closable
        destroyOnClose
        title="编辑消息"
        :visible="editMessageVisible"
        width="60%"
        @ok="editMessageOk"
        @cancel="editMessageCancel"
      >
        <a-form-model :model="MessageInfo" :rules="MessageRules" ref="addMessageRef">
          <a-form-model-item label="消息内容" prop="content">
            <a-input v-model="MessageInfo.content"></a-input>
          </a-form-model-item>
          <a-form-model-item label="发送者id" prop="sender_id">
            <a-input v-model="MessageInfo.sender_id"></a-input>
          </a-form-model-item>
          <a-form-model-item label="接收者id" prop="target_id">
            <a-input v-model="MessageInfo.target_id"></a-input>
          </a-form-model-item>
        </a-form-model>
      </a-modal>
    </div>
  </template>

  <script>
  const columns = [
    {
      title: 'id',
      dataIndex: 'id',
      width: '5%',
      key: 'id',
      align: 'center',
    },
    {
      title: '消息内容',
      dataIndex: 'content',
      width: '10%',
      key: 'content',
      align: 'center',
    },
    {
      title: '发送者id',
      dataIndex: 'sender_id',
      width: '10%',
      key: 'sender_id',
      align: 'center',
    },
    {
      title: '接收者id',
      dataIndex: 'target_id',
      width: '10%',
      key: 'type',
      align: 'center',
    },
    {
      title: '操作',
      width: '15%',
      key: 'action',
      align: 'center',
      scopedSlots: { customRender: 'action' },
    },
  ]

  export default {
    data() {
      return {
        pagination: {
          pageSizeOptions: ['20', '50', '100'],
          pageSize: 20,
          total: 0,
          showSizeChanger: true,
          showTotal: (total) => `共${total}条`,
        },
        Messagelist: [],
        MessageInfo: {
          content: '',
          sender_id: '',
          target_id: '',
          id: 0,
        },
        newMessage: {
          content: '',
          sender_id: '',
          target_id: '',
          id: 0,
        },
        columns,
        queryParam: {
          pagesize: 20,
          pagenum: 1,
        },
        editVisible: false,
        MessageRules: {
          content: [{ required: true, message: '请输入动态内容', trigger: 'change' }],
        },
        addMessageRules: {
          content: [{ required: true, message: '请输入动态内容', trigger: 'change' }],
        },
        editMessageVisible: false,
        addMessageVisible: false,
      }
    },
    created() {
      this.getMessageList()
    },
    methods: {
      // 获取消息列表
      async getMessageList() {
        const { data: res } = await this.$http.get('admin/message', {
          pagesize: this.queryParam.pagesize,
          pagenum: this.queryParam.pagenum,
        })

        // if (res.code !== 200) {
        //   if (res.code === 1004 || 1005 || 1006 || 1007) {
        //     window.sessionStorage.clear()
        //     this.$router.push('/login')
        //   }
        //   this.$message.error(res.message)
        // }
        this.Messagelist = res.data
        this.pagination.total = res.total
      },
      // 更改分页
      handleTableChange(pagination, filters, sorter) {
        var pager = { ...this.pagination }
        pager.current = pagination.current
        pager.pageSize = pagination.pageSize
        this.queryParam.pagesize = pagination.pageSize
        this.queryParam.pagenum = pagination.current

        if (pagination.pageSize !== this.pagination.pageSize) {
          this.queryParam.pagenum = 1
          pager.current = 1
        }
        this.pagination = pager
        this.getMessageList()
      },
      // 删除消息
      deleteMessage(id) {
        this.$confirm({
          title: '提示：请再次确认',
          content: '确定要删除该消息吗？一旦删除，无法恢复',
          onOk: async () => {
            const { data: res } = await this.$http.delete(`message/${id}`)
            if (res.status != 200) return this.$message.error(res.message)
            this.$message.success('删除成功')
            this.getMessageList()
          },
          onCancel: () => {
            this.$message.info('已取消删除')
          },
        })
      },
      // 新增消息
      addMessageOk() {
        this.$refs.addMessageRef.validate(async (valid) => {
          if (!valid) return this.$message.error('参数不符合要求，请重新输入')
          const { data: res } = await this.$http.post('message/send', {
            content: this.newMessage.content,
            sender_id: parseInt(this.newMessage.sender_id),
            target_id: parseInt(this.newMessage.target_id),
          })
          if (res.code != 200) return this.$message.error(res.message)
          this.$refs.addMessageRef.resetFields()
          this.addMessageVisible = false
          this.$message.success('添加消息成功')
          await this.getMessageList()
        })
      },
      addMessageCancel() {
        this.$refs.addMessageRef.resetFields()
        this.addMessageVisible = false
        this.$message.info('新增消息已取消')
      },
      // 编辑消息
      async editMessage(id) {
        this.editMessageVisible = true
        const { data: res } = await this.$http.get(`message/${id}`)
        this.MessageInfo = res.data
        this.MessageInfo.id = id
      },
      editMessageOk() {
        this.$refs.addMessageRef.validate(async (valid) => {
          if (!valid) return this.$message.error('参数不符合要求，请重新输入')
          const { data: res } = await this.$http.put(`message/${this.newMessage.id}`, {
            content: this.MessageInfo.content,
            sender_id: parseInt(this.newMessage.sender_id),
            target_id: parseInt(this.newMessage.target_id),
          })
          if (res.status != 200) return this.$message.error(res.message)
          this.editMessageVisible = false
          this.$message.success('更新消息信息成功')
          this.getMessageList()
        })
      },
      editMessageCancel() {
        this.$refs.addMessageRef.resetFields()
        this.editMessageVisible = false
        this.$message.info('编辑已取消')
      },
    },
  }
  </script>

  <style scoped>
  .actionSlot {
    display: flex;
    justify-content: center;
  }
  </style>
