<template>
    <div>
      <a-card>
        <a-row :gutter="20">
          <a-col :span="4">
            <a-button type="primary" @click="addNotificationVisible = true">新增通知</a-button>
          </a-col>
        </a-row>

        <a-table
          rowKey="id"
          :columns="columns"
          :pagination="pagination"
          :dataSource="Notificationlist"
          size="small"
          bordered
          @change="handleTableChange"
        >
          <template slot="action" slot-scope="data">
            <div class="actionSlot">
              <a-button type="primary" icon="edit" style="margin-right: 15px" @click="editNotification(data.id)">编辑</a-button>
              <a-button type="danger" icon="delete" style="margin-right: 15px" @click="deleteNotification(data.id)"
                >删除</a-button
              >
            </div>
          </template>
        </a-table>
      </a-card>

      <!-- 新增通知区域 -->
      <a-modal
        closable
        title="新增通知"
        :visible="addNotificationVisible"
        width="60%"
        @ok="addNotificationOk"
        @cancel="addNotificationCancel"
        destroyOnClose
      >
        <a-form-model :model="newNotification" :rules="addNotificationRules" ref="addNotificationRef">
          <a-form-model-item label="通知内容" prop="content">
            <a-input v-model="newNotification.content"></a-input>
          </a-form-model-item>
          <a-form-model-item label="通知对象" prop="sender_id">
            <a-input v-model="newNotification.sender_id"></a-input>
          </a-form-model-item>
          <a-form-model-item label="触发人" prop="target_id">
            <a-input v-model="newNotification.target_id"></a-input>
          </a-form-model-item>
        </a-form-model>
      </a-modal>

      <!-- 编辑通知区域 -->
      <a-modal
        closable
        destroyOnClose
        title="编辑通知"
        :visible="editNotificationVisible"
        width="60%"
        @ok="editNotificationOk"
        @cancel="editNotificationCancel"
      >
        <a-form-model :model="NotificationInfo" :rules="NotificationRules" ref="addNotificationRef">
            <a-form-model-item label="通知内容" prop="content">
            <a-input v-model="NotificationInfo.content"></a-input>
          </a-form-model-item>
          <a-form-model-item label="通知对象" prop="sender_id">
            <a-input v-model="NotificationInfo.sender_id"></a-input>
          </a-form-model-item>
          <a-form-model-item label="触发人" prop="target_id">
            <a-input v-model="NotificationInfo.target_id"></a-input>
          </a-form-model-item>

        </a-form-model>
      </a-modal>
    </div>
  </template>

  <script>
  const columns = [
    {
      title: 'id',
      dataIndex: 'id',
      width: '5%',
      key: 'id',
      align: 'center',
    },
    {
      title: '触发人',
      dataIndex: 'user.nickname',
      width: '10%',
      key: 'target_id',
      align: 'center',
    },
    {
      title: 'created_at',
      dataIndex: 'created_at',
      width: '5%',
      key: 'created_at',
      align: 'center',
    },
    {
      title: '通知内容',
      dataIndex: 'content',
      width: '10%',
      key: 'content',
      align: 'center',
    },
    {
      title: '通知对象',
      dataIndex: 'source_id',
      width: '10%',
      key: 'source_id',
      align: 'center',
    },
    {
      title: 'ext',
      dataIndex: 'ext',
      width: '10%',
      key: 'ext',
      align: 'center',
    },
    {
      title: 'text',
      dataIndex: 'text',
      width: '10%',
      key: 'text',
      align: 'center',
    },
    {
      title: '操作',
      width: '15%',
      key: 'action',
      align: 'center',
      scopedSlots: { customRender: 'action' },
    },
  ]

  export default {
    data() {
      return {
        pagination: {
          pageSizeOptions: ['20', '50', '100'],
          pageSize: 20,
          total: 0,
          showSizeChanger: true,
          showTotal: (total) => `共${total}条`,
        },
        Notificationlist: [],
        NotificationInfo: {
          content: '',
          sender_id: '',
          target_id: '',
          id: 0,
        },
        newNotification: {
          content: '',
          sender_id: '',
          target_id: '',
        },
        columns,
        queryParam: {
          pagesize: 5,
          pagenum: 1,
        },
        editVisible: false,
        NotificationRules: {
          sku: [
            {
              validator: (rule, value, callback) => {
                if (this.NotificationInfo.sku === '') {
                  callback(new Error('请输入通知sku'))
                  return
                }
                callback()
              },
              trigger: 'blur',
            },
          ],
          name: [
            {
              validator: (rule, value, callback) => {
                if (this.NotificationInfo.name === '') {
                  callback(new Error('请输入通知名'))
                  return
                }
                callback()
              },
              trigger: 'blur',
            },
          ],
          type: [
            {
              validator: (rule, value, callback) => {
                if (this.NotificationInfo.type === '') {
                  callback(new Error('请输入通知类型（0，string，1，json）'))
                  return
                }
                callback()
              },
              trigger: 'blur',
            },
          ],
          price: [
            {
              validator: (rule, value, callback) => {
                if (this.NotificationInfo.price === '') {
                  callback(new Error('请输入通知封面value'))
                  return
                }
                callback()
              },
              trigger: 'blur',
            },
          ],
          desc: [
            {
              validator: (rule, value, callback) => {
                if (this.NotificationInfo.desc === '') {
                  callback(new Error('请输入通知描述，英文名'))
                  return
                }
                callback()
              },
              trigger: 'blur',
            },
          ]
        },
        addNotificationRules: {
           sku: [
            {
              validator: (rule, value, callback) => {
                if (this.newNotification.sku === '') {
                  callback(new Error('请输入通知sku'))
                  return
                }
                callback()
              },
              trigger: 'blur',
            },
          ],
          name: [
            {
              validator: (rule, value, callback) => {
                if (this.newNotification.name === '') {
                  callback(new Error('请输入通知名'))
                  return
                }
                callback()
              },
              trigger: 'blur',
            },
          ],
          type: [
            {
              validator: (rule, value, callback) => {
                if (this.newNotification.type === '') {
                  callback(new Error('请输入通知类型（0，数据结构，1，算法）'))
                  return
                }
                callback()
              },
              trigger: 'blur',
            },
          ],
          price: [
            {
              validator: (rule, value, callback) => {
                if (this.newNotification.price === '') {
                  callback(new Error('请输入通知价格'))
                  return
                }
                callback()
              },
              trigger: 'blur',
            },
          ],
          desc: [
            {
              validator: (rule, value, callback) => {
                if (this.newNotification.desc === '') {
                  callback(new Error('请输入通知描述'))
                  return
                }
                callback()
              },
              trigger: 'blur',
            },
          ]
        },
        editNotificationVisible: false,
        addNotificationVisible: false,
      }
    },
    created() {
      this.getNotificationList()
    },
    methods: {
      // 获取通知列表
      async getNotificationList() {
        const { data: res } = await this.$http.get('notifications', {
          pagesize: this.queryParam.pagesize,
          pagenum: this.queryParam.pagenum,
        })

        // if (res.code !== 200) {
        //   if (res.code === 1004 || 1005 || 1006 || 1007) {
        //     window.sessionStorage.clear()
        //     this.$router.push('/login')
        //   }
        //   this.$message.error(res.message)
        // }
        this.Notificationlist = res.data
        this.pagination.total = res.total
      },
      // 更改分页
      handleTableChange(pagination, filters, sorter) {
        var pager = { ...this.pagination }
        pager.current = pagination.current
        pager.pageSize = pagination.pageSize
        this.queryParam.pagesize = pagination.pageSize
        this.queryParam.pagenum = pagination.current

        if (pagination.pageSize !== this.pagination.pageSize) {
          this.queryParam.pagenum = 1
          pager.current = 1
        }
        this.pagination = pager
        this.getNotificationList()
      },
      // 删除通知
      deleteNotification(id) {
        this.$confirm({
          title: '提示：请再次确认',
          content: '确定要删除该通知吗？一旦删除，无法恢复',
          onOk: async () => {
            const { data: res } = await this.$http.delete(`notification/${id}`)
            if (res.status != 200) return this.$message.error(res.message)
            this.$message.success('删除成功')
            this.getNotificationList()
          },
          onCancel: () => {
            this.$message.info('已取消删除')
          },
        })
      },
      // 新增通知
      addNotificationOk() {
        this.$refs.addNotificationRef.validate(async (valid) => {
          if (!valid) return this.$message.error('参数不符合要求，请重新输入')
          const { data: res } = await this.$http.post('notification/add', {
            sender_id: parseInt(this.newNotification.sender_id),
            target_id: parseInt(this.newNotification.target_id),
            content: this.newNotification.content
          })
          if (res.code != 200) return this.$message.error(res.message)
          this.$refs.addNotificationRef.resetFields()
          this.addNotificationVisible = false
          this.$message.success('添加通知成功')
          await this.getNotificationList()
        })
      },
      addNotificationCancel() {
        this.$refs.addNotificationRef.resetFields()
        this.addNotificationVisible = false
        this.$message.info('新增通知已取消')
      },
      // 编辑通知
      async editNotification(id) {
        this.editNotificationVisible = true
        const { data: res } = await this.$http.get(`notification/${id}`)
        this.NotificationInfo = res.data
        this.NotificationInfo.id = id
      },
      editNotificationOk() {
        this.$refs.addNotificationRef.validate(async (valid) => {
          if (!valid) return this.$message.error('参数不符合要求，请重新输入')
          const { data: res } = await this.$http.put(`notification/${this.NotificationInfo.id}`, {
            sender_id: parseInt(this.NotificationInfo.sender_id),
            target_id: parseInt(this.NotificationInfo.target_id),
            content: this.NotificationInfo.content,
          })
          if (res.status != 200) return this.$message.error(res.message)
          this.editNotificationVisible = false
          this.$message.success('更新通知信息成功')
          this.getNotificationList()
        })
      },
      editNotificationCancel() {
        this.$refs.addNotificationRef.resetFields()
        this.editNotificationVisible = false
        this.$message.info('编辑已取消')
      },
    },
  }
  </script>

  <style scoped>
  .actionSlot {
    display: flex;
    justify-content: center;
  }
  </style>
