<template>
  <div>
    <a-card>
      <a-row :gutter="20">
        <a-col :span="4">
          <a-button type="primary" @click="addChannelVisible = true">新增分类</a-button>
        </a-col>
      </a-row>

      <a-table
        rowKey="id"
        :columns="columns"
        :pagination="pagination"
        :dataSource="Channellist"
        size="small"
        bordered
        @change="handleTableChange"
      >
        <template slot="action" slot-scope="data">
          <div class="actionSlot">
            <a-button type="primary" icon="edit" style="margin-right: 15px" @click="editChannel(data.id)">编辑</a-button>
            <a-button type="danger" icon="delete" style="margin-right: 15px" @click="deleteChannel(data.id)"
              >删除</a-button
            >
          </div>
        </template>
      </a-table>
    </a-card>

    <!-- 新增分类区域 -->
    <a-modal
      closable
      title="新增分类"
      :visible="addChannelVisible"
      width="60%"
      @ok="addChannelOk"
      @cancel="addChannelCancel"
      destroyOnClose
    >
      <a-form-model :model="newChannel" :rules="addChannelRules" ref="addChannelRef">
        <a-form-model-item label="分类名称" prop="name">
          <a-input v-model="newChannel.name"></a-input>
        </a-form-model-item>
        <a-form-model-item label="分类类型" prop="type">
          <a-input v-model="newChannel.type"></a-input>
        </a-form-model-item>
        <a-form-model-item label="分类icon" prop="url">
          <a-input v-model="newChannel.url"></a-input>
        </a-form-model-item>
        <a-form-model-item label="分类描述" prop="desc">
          <a-input v-model="newChannel.desc"></a-input>
        </a-form-model-item>
      </a-form-model>
    </a-modal>

    <!-- 编辑分类区域 -->
    <a-modal
      closable
      destroyOnClose
      title="编辑分类"
      :visible="editChannelVisible"
      width="60%"
      @ok="editChannelOk"
      @cancel="editChannelCancel"
    >
      <a-form-model :model="ChannelInfo" :rules="ChannelRules" ref="addChannelRef">
        <a-form-model-item label="分类名称" prop="name">
          <a-input v-model="ChannelInfo.name"></a-input>
        </a-form-model-item>
        <a-form-model-item label="分类类型" prop="type">
          <a-input v-model="ChannelInfo.type"></a-input>
        </a-form-model-item>
        <a-form-model-item label="分类icon" prop="url">
          <a-input v-model="ChannelInfo.url"></a-input>
        </a-form-model-item>
        <a-form-model-item label="分类描述" prop="desc">
          <a-input v-model="ChannelInfo.desc"></a-input>
        </a-form-model-item>
      </a-form-model>
    </a-modal>
  </div>
</template>

<script>
const columns = [
  {
    title: 'id',
    dataIndex: 'id',
    width: '10%',
    key: 'id',
    align: 'center',
  },
  {
    title: '分类名',
    dataIndex: 'name',
    width: '10%',
    key: 'name',
    align: 'center',
  },
  {
    title: '分类描述',
    dataIndex: 'desc',
    width: '10%',
    key: 'desc',
    align: 'center',
  },
  {
    title: '分类type',
    dataIndex: 'type',
    width: '5%',
    key: 'type',
    align: 'center',
  },
  {
    title: '分类url',
    dataIndex: 'url',
    width: '20%',
    key: 'url',
    align: 'center',
  },
  {
    title: '操作',
    width: '30%',
    key: 'action',
    align: 'center',
    scopedSlots: { customRender: 'action' },
  },
]

export default {
  data() {
    return {
      pagination: {
        pageSizeOptions: ['20', '50', '100'],
        pageSize: 5,
        total: 0,
        showSizeChanger: true,
        showTotal: (total) => `共${total}条`,
      },
      Channellist: [],
      ChannelInfo: {
        name: '',
        type: '',
        url: '',
        desc: '',
        id: 0,
      },
      newChannel: {
        name: '',
        type: '',
        url: '',
        desc: '',
      },
      columns,
      queryParam: {
        pagesize: 20,
        pagenum: 1,
      },
      editVisible: false,
      ChannelRules: {
        name: [
          {
            validator: (rule, value, callback) => {
              if (this.ChannelInfo.name === '') {
                callback(new Error('请输入分类名'))
                return
              }
              callback()
            },
            trigger: 'blur',
          },
        ],
        type: [
          {
            validator: (rule, value, callback) => {
              if (this.ChannelInfo.type === '') {
                callback(new Error('请输入分类类型（0，数据结构，1，算法）'))
                return
              }
              callback()
            },
            trigger: 'blur',
          },
        ],
        url: [
          {
            validator: (rule, value, callback) => {
              if (this.ChannelInfo.url === '') {
                callback(new Error('请输入分类封面url'))
                return
              }
              callback()
            },
            trigger: 'blur',
          },
        ],
        desc: [
          {
            validator: (rule, value, callback) => {
              if (this.ChannelInfo.desc === '') {
                callback(new Error('请输入分类描述，英文名'))
                return
              }
              callback()
            },
            trigger: 'blur',
          },
        ]
      },
      addChannelRules: {
        name: [
          {
            validator: (rule, value, callback) => {
              if (this.newChannel.name === '') {
                callback(new Error('请输入分类名'))
                return
              }
              callback()
            },
            trigger: 'blur',
          },
        ],
        type: [
          {
            validator: (rule, value, callback) => {
              if (this.newChannel.type === '') {
                callback(new Error('请输入分类类型（0，数据结构，1，算法）'))
                return
              }
              callback()
            },
            trigger: 'blur',
          },
        ],
        url: [
          {
            validator: (rule, value, callback) => {
              if (this.newChannel.url === '') {
                callback(new Error('请输入分类封面url'))
                return
              }
              callback()
            },
            trigger: 'blur',
          },
        ],
        desc: [
          {
            validator: (rule, value, callback) => {
              if (this.newChannel.desc === '') {
                callback(new Error('请输入分类描述，英文名'))
                return
              }
              callback()
            },
            trigger: 'blur',
          },
        ]
      },
      editChannelVisible: false,
      addChannelVisible: false,
    }
  },
  created() {
    this.getChannelList()
  },
  methods: {
    // 获取分类列表
    async getChannelList() {
      const { data: res } = await this.$http.get('/channel', {
        pagesize: this.queryParam.pagesize,
        pagenum: this.queryParam.pagenum,
      })

      // if (res.status !== 200) {
      //   if (res.status === 1004 || 1005 || 1006 || 1007) {
      //     window.sessionStorage.clear()
      //     this.$router.push('/login')
      //   }
      //   this.$message.error(res.message)
      // }
      this.Channellist = res.data
      this.pagination.total = res.total
    },
    // 更改分页
    handleTableChange(pagination, filters, sorter) {
      var pager = { ...this.pagination }
      pager.current = pagination.current
      pager.pageSize = pagination.pageSize
      this.queryParam.pagesize = pagination.pageSize
      this.queryParam.pagenum = pagination.current

      if (pagination.pageSize !== this.pagination.pageSize) {
        this.queryParam.pagenum = 1
        pager.current = 1
      }
      this.pagination = pager
      this.getChannelList()
    },
    // 删除分类
    deleteChannel(id) {
      this.$confirm({
        title: '提示：请再次确认',
        content: '确定要删除该分类吗？一旦删除，无法恢复',
        onOk: async () => {
          const { data: res } = await this.$http.post(`channel/delete/${id}`)
          if (res.status != 200) return this.$message.error(res.message)
          this.$message.success('删除成功')
          this.getChannelList()
        },
        onCancel: () => {
          this.$message.info('已取消删除')
        },
      })
    },
    // 新增分类
    addChannelOk() {
      this.$refs.addChannelRef.validate(async (valid) => {
        if (!valid) return this.$message.error('参数不符合要求，请重新输入')
        const { data: res } = await this.$http.post('channel/add', {
          name: this.newChannel.name,
          desc: this.newChannel.desc,
          type: parseInt(this.newChannel.type),
          url:  this.newChannel.url,
        })
        if (res.status != 200) return this.$message.error(res.message)
        this.$refs.addChannelRef.resetFields()
        this.addChannelVisible = false
        this.$message.success('添加分类成功')
        await this.getChannelList()
      })
    },
    addChannelCancel() {
      this.$refs.addChannelRef.resetFields()
      this.addChannelVisible = false
      this.$message.info('新增分类已取消')
    },
    // 编辑分类
    async editChannel(id) {
      this.editChannelVisible = true
      const { data: res } = await this.$http.get(`channel/${id}`)
      this.ChannelInfo = res.data
      this.ChannelInfo.id = id
    },
    editChannelOk() {
      this.$refs.addChannelRef.validate(async (valid) => {
        if (!valid) return this.$message.error('参数不符合要求，请重新输入')
        const { data: res } = await this.$http.post(`channel/edit/${this.ChannelInfo.id}`, {
          name: this.ChannelInfo.name,
          desc: this.ChannelInfo.desc,
          type: parseInt(this.ChannelInfo.type),
          url:  this.ChannelInfo.url,
        })
        if (res.status != 200) return this.$message.error(res.message)
        this.editChannelVisible = false
        this.$message.success('更新分类信息成功')
        this.getChannelList()
      })
    },
    editChannelCancel() {
      this.$refs.addChannelRef.resetFields()
      this.editChannelVisible = false
      this.$message.info('编辑已取消')
    },
  },
}
</script>

<style scoped>
.actionSlot {
  display: flex;
  justify-content: center;
}
</style>
