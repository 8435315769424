<template>
    <div>
      <a-card>
        <h3>{{ id ? '编辑动态' : '新增动态' }}</h3>
  
        <a-form-model :model="momentInfo" ref="momentInfoRef" :rules="momentInfoRules"
          :hideRequiredMark="true">
          <a-row :gutter="24">
            <a-form-model-item label="动态内容" prop="title">
                <a-textarea style="height: 300px" v-model="momentInfo.content" placeholder="分享经验"></a-textarea>
            </a-form-model-item>
      
          </a-row>
          
  
          <a-form-model-item>
            <a-button type="danger" style="margin-right: 15px" @click="momentOk(momentInfo.id)">
              {{
                  momentInfo.id ? '更新' : '提交'
              }}
            </a-button>
            <a-button type="primary" @click="addCancel">取消</a-button>
          </a-form-model-item>
        </a-form-model>
      </a-card>
    </div>
  </template>
  
  <script>
  import { Url } from '../../plugin/http'
  
  export default {
    
    props: ['id'],
    data() {
      return {
        momentInfo: {
          uid: 1,
          id: 0,
          title: '',
          cid: undefined,
          desc: '',
          content: '',
          img: '',
        },
        Catelist: [],
        upUrl: Url + 'upload',
        headers: {},
        fileList: [],
        momentInfoRules: {
          content: [{ required: true, message: '请输入动态内容', trigger: 'change' }],
        },
      }
    },
    mounted() {
      this.getCateList()
      this.headers = { Authorization: `Bearer ${window.sessionStorage.getItem('token')}` }
      if (this.id) {
        this.getMomentInfo(this.id)
      }
    },
    methods: {
      // 查询动态信息
      async getMomentInfo(id) {
        const { data: res } = await this.$http.get(`moment/${id}`)
        // if (res.status !== 200) {
        //   if (res.status === 1004 || 1005 || 1006 || 1007) {
        //     window.sessionStorage.clear()
        //     this.$message.error(res.message)    
        //     this.$router.push('/login')
        //     return
        //   }
        //   this.$message.error(res.message)
        //   return
        // }
        this.momentInfo = res.data
        this.momentInfo.id = res.data.id
      },
      // 获取分类列表
      async getCateList() {
        const { data: res } = await this.$http.get('admin/category')
        if (res.status !== 200) return this.$message.error(res.message)
        this.Catelist = res.data
      },
      // 选择分类
      cateChange(value) {
        this.momentInfo.cid = value
      },
      // 上传图片
      upChange(info) {
        if (info.file.status !== 'uploading') {
        }
        if (info.file.status === 'done') {
          this.$message.success(`图片上传成功`)
          this.momentInfo.img = info.file.response.url
        } else if (info.file.status === 'error') {
          this.$message.error(`图片上传失败`)
        }
      },
      // 提交&&更新动态
      momentOk(id) {
        this.$refs.momentInfoRef.validate(async (valid) => {
          if (!valid) return this.$message.error('参数验证未通过，请按要求录入动态内容')
          if (id === 0) {
            let formData = new FormData();
            formData.append("content",this.momentInfo.content);
            const { data: res } = await this.$http.post('moment/add', formData, {
              headers: {
                "Content-Type" : "application/x-www-form-urlencoded",
              }
            })
            if (res.status !== 200) return this.$message.error(res.message)
            this.$router.push('/momentlist')
            this.$message.success('添加动态成功')
          } else {
            const { data: res } = await this.$http.put(`moment/${id}`, this.momentInfo)
            if (res.status !== 200) return this.$message.error(res.message)
  
            this.$router.push('/momentlist')
            this.$message.success('更新动态成功')
          }
        })
      },
  
      addCancel() {
        this.$refs.momentInfoRef.resetFields()
      },
    },
  }
  </script>