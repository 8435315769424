<template>
    <div>
      <a-card>
<!--        <a-row :gutter="20">-->
<!--          <a-col :span="4">-->
<!--            <a-button type="primary" @click="addOrderVisible = true">新增分类</a-button>-->
<!--          </a-col>-->
<!--        </a-row>-->

        <a-table
          rowKey="id"
          :columns="columns"
          :pagination="pagination"
          :dataSource="Orderlist"
          size="small"
          bordered
          @change="handleTableChange"
        >
<!--          <template slot="action" slot-scope="data">-->
<!--            <div class="actionSlot">-->
<!--              <a-button type="primary" icon="edit" style="margin-right: 15px" @click="editOrder(data.id)">编辑</a-button>-->
<!--              <a-button type="danger" icon="delete" style="margin-right: 15px" @click="deleteOrder(data.id)"-->
<!--                >删除</a-button-->
<!--              >-->
<!--            </div>-->
<!--          </template>-->
        </a-table>
      </a-card>

      <!-- 新增分类区域 -->
      <a-modal
        closable
        title="新增分类"
        :visible="addOrderVisible"
        width="60%"
        @ok="addOrderOk"
        @cancel="addOrderCancel"
        destroyOnClose
      >
        <a-form-model :model="newOrder" :rules="addOrderRules" ref="addOrderRef">
          <a-form-model-item label="分类名称" prop="name">
            <a-input v-model="newOrder.name"></a-input>
          </a-form-model-item>
          <a-form-model-item label="分类类型" prop="type">
            <a-input v-model="newOrder.type"></a-input>
          </a-form-model-item>
          <a-form-model-item label="分类icon" prop="url">
            <a-input v-model="newOrder.url"></a-input>
          </a-form-model-item>
          <a-form-model-item label="分类描述" prop="desc">
            <a-input v-model="newOrder.desc"></a-input>
          </a-form-model-item>
        </a-form-model>
      </a-modal>

      <!-- 编辑分类区域 -->
      <a-modal
        closable
        destroyOnClose
        title="编辑分类"
        :visible="editOrderVisible"
        width="60%"
        @ok="editOrderOk"
        @cancel="editOrderCancel"
      >
        <a-form-model :model="OrderInfo" :rules="OrderRules" ref="addOrderRef">
          <a-form-model-item label="分类名称" prop="name">
            <a-input v-model="OrderInfo.name"></a-input>
          </a-form-model-item>
          <a-form-model-item label="分类类型" prop="type">
            <a-input v-model="OrderInfo.type"></a-input>
          </a-form-model-item>
          <a-form-model-item label="分类icon" prop="url">
            <a-input v-model="OrderInfo.url"></a-input>
          </a-form-model-item>
          <a-form-model-item label="分类描述" prop="desc">
            <a-input v-model="OrderInfo.desc"></a-input>
          </a-form-model-item>
        </a-form-model>
      </a-modal>
    </div>
  </template>

  <script>
  const columns = [
    {
      title: 'id',
      dataIndex: 'id',
      width: '5%',
      key: 'id',
      align: 'center',
    },
    {
      title: '订单号',
      dataIndex: 'out_trade_no',
      width: '10%',
      key: 'out_trade_no',
      align: 'center',
    },
    {
      title: '订单状态',
      dataIndex: 'status',
      width: '5%',
      key: 'status',
      align: 'center',
    },
    {
      title: '商品SKU',
      dataIndex: 'sku',
      width: '10%',
      key: 'sku',
      align: 'center',
    },
    {
      title: '下单时间',
      dataIndex: 'created_at',
      width: '20%',
      key: 'created_at',
      align: 'center',
    },
    // {
    //   title: '操作',
    //   width: '30%',
    //   key: 'action',
    //   align: 'center',
    //   scopedSlots: { customRender: 'action' },
    // },
  ]

  export default {
    data() {
      return {
        pagination: {
          pageSizeOptions: ['20', '50', '100'],
          pageSize: 20,
          total: 0,
          showSizeChanger: true,
          showTotal: (total) => `共${total}条`,
        },
        Orderlist: [],
        OrderInfo: {
          name: '',
          type: '',
          url: '',
          desc: '',
          id: 0,
        },
        newOrder: {
          name: '',
          type: '',
          url: '',
          desc: '',
        },
        columns,
        queryParam: {
          pagesize: 20,
          pagenum: 1,
        },
        editVisible: false,
        OrderRules: {
          name: [
            {
              validator: (rule, value, callback) => {
                if (this.OrderInfo.name === '') {
                  callback(new Error('请输入分类名'))
                  return
                }
                callback()
              },
              trigger: 'blur',
            },
          ],
          type: [
            {
              validator: (rule, value, callback) => {
                if (this.OrderInfo.type === '') {
                  callback(new Error('请输入分类类型（0，数据结构，1，算法）'))
                  return
                }
                callback()
              },
              trigger: 'blur',
            },
          ],
          url: [
            {
              validator: (rule, value, callback) => {
                if (this.OrderInfo.url === '') {
                  callback(new Error('请输入分类封面url'))
                  return
                }
                callback()
              },
              trigger: 'blur',
            },
          ],
          desc: [
            {
              validator: (rule, value, callback) => {
                if (this.OrderInfo.desc === '') {
                  callback(new Error('请输入分类描述，英文名'))
                  return
                }
                callback()
              },
              trigger: 'blur',
            },
          ]
        },
        addOrderRules: {
          name: [
            {
              validator: (rule, value, callback) => {
                if (this.newOrder.name === '') {
                  callback(new Error('请输入分类名'))
                  return
                }
                callback()
              },
              trigger: 'blur',
            },
          ],
          type: [
            {
              validator: (rule, value, callback) => {
                if (this.newOrder.type === '') {
                  callback(new Error('请输入分类类型（0，数据结构，1，算法）'))
                  return
                }
                callback()
              },
              trigger: 'blur',
            },
          ],
          url: [
            {
              validator: (rule, value, callback) => {
                if (this.newOrder.url === '') {
                  callback(new Error('请输入分类封面url'))
                  return
                }
                callback()
              },
              trigger: 'blur',
            },
          ],
          desc: [
            {
              validator: (rule, value, callback) => {
                if (this.newOrder.desc === '') {
                  callback(new Error('请输入分类描述，英文名'))
                  return
                }
                callback()
              },
              trigger: 'blur',
            },
          ]
        },
        editOrderVisible: false,
        addOrderVisible: false,
      }
    },
    created() {
      this.getOrderList()
    },
    methods: {
      // 获取分类列表
      async getOrderList() {
        const { data: res } = await this.$http.get('/order', {
          pagesize: this.queryParam.pagesize,
          pagenum: this.queryParam.pagenum,
        })

        // if (res.status !== 200) {
        //   if (res.status === 1004 || 1005 || 1006 || 1007) {
        //     window.sessionStorage.clear()
        //     this.$router.push('/login')
        //   }
        //   this.$message.error(res.message)
        // }
        this.Orderlist = res.data
        this.pagination.total = res.total
      },
      // 更改分页
      handleTableChange(pagination, filters, sorter) {
        var pager = { ...this.pagination }
        pager.current = pagination.current
        pager.pageSize = pagination.pageSize
        this.queryParam.pagesize = pagination.pageSize
        this.queryParam.pagenum = pagination.current

        if (pagination.pageSize !== this.pagination.pageSize) {
          this.queryParam.pagenum = 1
          pager.current = 1
        }
        this.pagination = pager
        this.getOrderList()
      },
      // 删除分类
      deleteOrder(id) {
        this.$confirm({
          title: '提示：请再次确认',
          content: '确定要删除该分类吗？一旦删除，无法恢复',
          onOk: async () => {
            const { data: res } = await this.$http.delete(`order/${id}`)
            if (res.status != 200) return this.$message.error(res.message)
            this.$message.success('删除成功')
            this.getOrderList()
          },
          onCancel: () => {
            this.$message.info('已取消删除')
          },
        })
      },
      // 新增分类
      addOrderOk() {
        this.$refs.addOrderRef.validate(async (valid) => {
          if (!valid) return this.$message.error('参数不符合要求，请重新输入')
          const { data: res } = await this.$http.post('order/add', {
            name: this.newOrder.name,
            desc: this.newOrder.desc,
            type: parseInt(this.newOrder.type),
            url:  this.newOrder.url,
          })
          if (res.status != 200) return this.$message.error(res.message)
          this.$refs.addOrderRef.resetFields()
          this.addOrderVisible = false
          this.$message.success('添加分类成功')
          await this.getOrderList()
        })
      },
      addOrderCancel() {
        this.$refs.addOrderRef.resetFields()
        this.addOrderVisible = false
        this.$message.info('新增分类已取消')
      },
      // 编辑分类
      async editOrder(id) {
        this.editOrderVisible = true
        const { data: res } = await this.$http.get(`order/${id}`)
        this.OrderInfo = res.data
        this.OrderInfo.id = id
      },
      editOrderOk() {
        this.$refs.addOrderRef.validate(async (valid) => {
          if (!valid) return this.$message.error('参数不符合要求，请重新输入')
          const { data: res } = await this.$http.put(`order/${this.OrderInfo.id}`, {
            name: this.OrderInfo.name,
            desc: this.OrderInfo.desc,
            type: parseInt(this.OrderInfo.type),
            url:  this.OrderInfo.url,
          })
          if (res.status != 200) return this.$message.error(res.message)
          this.editOrderVisible = false
          this.$message.success('更新分类信息成功')
          this.getOrderList()
        })
      },
      editOrderCancel() {
        this.$refs.addOrderRef.resetFields()
        this.editOrderVisible = false
        this.$message.info('编辑已取消')
      },
    },
  }
  </script>

  <style scoped>
  .actionSlot {
    display: flex;
    justify-content: center;
  }
  </style>
