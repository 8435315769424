<template>
    <div>
      <a-card>
        <a-row :gutter="20">
          <a-col :span="6">
            <a-input-search
              v-model="queryParam.username"
              placeholder="输入用户名查找"
              enter-button
              allowClear
              @search="searchUser"
            />
          </a-col>
          <a-col :span="4">
            <a-button type="primary" @click="addUserVisible = true">新增</a-button>
          </a-col>

          <a-col :span="3">
            <a-select placeholder="请选择分类" style="width: 200px" @change="CategoryChange">
              <a-select-option
                v-for="item in Catelist"
                :key="item.id"
                :value="item.id"
              >{{ item.name }}</a-select-option>
            </a-select>
          </a-col>
          <a-col :span="1">
            <a-button type="info" @click="getMomentList()">显示全部</a-button>
          </a-col>
        </a-row>
  
        <a-table
          rowKey="id"
          :columns="columns"
          :pagination="pagination"
          :dataSource="userlist"
          size="small"
          bordered
          @change="handleTableChange"
        >
          <span class="AvatarImg" slot="img" slot-scope="img">
              <img :src="img" />
          </span>

          <span slot="role" slot-scope="data">{{ data == 1 ? '管理员' : '订阅者' }}</span>
          <template slot="action" slot-scope="data">
            <div class="actionSlot">
              <a-button size="small" type="primary" icon="edit" style="margin-right: 15px" @click="editUser(data.id)">通过</a-button>
              <a-button size="small" type="danger" icon="edit" style="margin-right: 15px" @click="deleteUser(data.id)">不通过</a-button>
            </div>
          </template>
        </a-table>
      </a-card>
  
      <!-- 新增用户区域 -->
      <a-modal
        closable
        title="新增用户"
        :visible="addUserVisible"
        width="60%"
        @ok="addUserOk"
        @cancel="addUserCancel"
        destroyOnClose
      >
        <a-form-model :model="newUser" :rules="addUserRules" ref="addUserRef">
          <a-form-model-item label="用户名" prop="username">
            <a-input v-model="newUser.username"></a-input>
          </a-form-model-item>
          <a-form-model-item has-feedback label="密码" prop="password">
            <a-input-password v-model="newUser.password"></a-input-password>
          </a-form-model-item>
          <a-form-model-item has-feedback label="确认密码" prop="checkpass">
            <a-input-password v-model="newUser.checkpass"></a-input-password>
          </a-form-model-item>
        </a-form-model>
      </a-modal>
  
      <!-- 编辑用户区域 -->
      <a-modal
        closable
        destroyOnClose
        title="编辑用户"
        :visible="editUserVisible"
        width="60%"
        @ok="editUserOk"
        @cancel="editUserCancel"
      >
        <a-form-model :model="userInfo" :rules="userRules" ref="addUserRef">
          <a-form-model-item label="用户名" prop="username">
            <a-input v-model="userInfo.username"></a-input>
          </a-form-model-item>
          <a-form-model-item label="昵称" prop="nickname">
            <a-input v-model="userInfo.nickname"></a-input>
          </a-form-model-item>
          <a-form-model-item label="上传头像" prop="img">
                <a-upload listType="picture" name="file" :action="upUrl" :headers="headers"
                  @change="upChange">
                  <a-button>
                    <a-icon type="upload" />点击上传
                  </a-button>
  
                  <template v-if="id">
                    <img :src="artInfo.img" style="width: 120px; height: 100px; margin-left: 15px" />
                  </template>
                </a-upload>
              </a-form-model-item>
          <a-form-model-item label="是否为管理员">
            <a-switch
              :checked="IsAdmin"
              checked-children="是"
              un-checked-children="否"
              @change="adminChange"
            />
          </a-form-model-item>
        </a-form-model>
      </a-modal>
  
      <!-- 修改密码 -->
      <a-modal
        closable
        title="修改密码"
        :visible="changePasswordVisible"
        width="60%"
        @ok="changePasswordOk"
        @cancel="changePasswordCancel"
        destroyOnClose
      >
        <a-form-model :model="changePassword" :rules="changePasswordRules" ref="changePasswordRef">
          <a-form-model-item has-feedback label="密码" prop="password">
            <a-input-password v-model="changePassword.password"></a-input-password>
          </a-form-model-item>
          <a-form-model-item has-feedback label="确认密码" prop="checkpass">
            <a-input-password v-model="changePassword.checkpass"></a-input-password>
          </a-form-model-item>
        </a-form-model>
      </a-modal>
    </div>
  </template>
  
  <script>
  import day from 'dayjs'
  import { Url } from '../../plugin/http'
  
  const columns = [
    {
      title: 'id',
      dataIndex: 'id',
      width: '5%',
      key: 'id',
      align: 'center',
    },
    {
      title: 'uid',
      dataIndex: 'uid',
      width: '5%',
      key: 'uid',
      align: 'center',
    },
    {
      title: 'src_type',
      dataIndex: 'src_type',
      width: '5%',
      key: 'src_type',
      align: 'center',
    },
    {
      title: 'src_value_new',
      dataIndex: 'src_value_new',
      width: '10%',
      key: 'src_value_new',
      align: 'center',
      scopedSlots: { customRender: 'img' },
    },
    {
      title: 'src_value_old',
      dataIndex: 'src_value_old',
      width: '10%',
      key: 'src_value_old',
      align: 'center',
      scopedSlots: { customRender: 'img' },
    },
    {
      title: 'src_value_new',
      dataIndex: 'src_value_new',
      width: '20%',
      key: 'src_value_new',
      align: 'center',
    },
    {
      title: 'src_value_old',
      dataIndex: 'src_value_old',
      width: '20%',
      key: 'src_value_old',
      align: 'center',
    },
    {
      title: '注册时间',
      dataIndex: 'created_at',
      width: '10%',
      key: 'created_at',
      align: 'center',
      customRender: (val) => {
        return val ? day(val).format('YYYY年MM月DD日 HH:mm') : '暂无'
      },
    },
    {
      title: '角色',
      dataIndex: 'role',
      width: '5%',
      key: 'role',
      align: 'center',
      scopedSlots: { customRender: 'role' },
    },
    {
      title: '操作',
      width: '30%',
      key: 'action',
      align: 'center',
      scopedSlots: { customRender: 'action' },
    },
  ]
  
  export default {
    data() {
      return {
        headers: {},
        upUrl: Url + 'upload',
        pagination: {
          pageSizeOptions: ['20', '50', '100'],
          pageSize: 20,
          total: 0,
          showSizeChanger: true,
          showTotal: (total) => `共${total}条`,
        },
        Catelist: [
            {
                id: 'cover',
                name: '封面',
            },
            {
                id: 'avatar',
                name: '头像',
            },
            {
                id: 'nickname',
                name: '昵称',
            },
            {
                id: 'usernmae',
                name: '抖文号',
            },
            {
                id: 'introduce',
                name: '简介',
            },
        ],
        userlist: [],
        userInfo: {
          username: '',
          nickname: '',
          password: '',
          avatar: '',
          role: 2,
          checkPass: '',
        },
        newUser: {
          username: '',
          password: '',
          role: 2,
          checkPass: '',
        },
        changePassword: {
          id: 0,
          password: '',
          checkPass: '',
        },
        columns,
        queryParam: {
          srcType: 'avatar',
          pagesize: 20,
          pagenum: 0,
        },
        editVisible: false,
        userRules: {
          username: [
            {
              validator: (rule, value, callback) => {
                if (this.userInfo.username == '') {
                  callback(new Error('请输入用户名'))
                }
                if ([...this.userInfo.username].length < 4 || [...this.userInfo.username].length > 12) {
                  callback(new Error('用户名应当在4到12个字符之间'))
                } else {
                  callback()
                }
              },
              trigger: 'blur',
            },
          ],
          password: [
            {
              validator: (rule, value, callback) => {
                if (this.userInfo.password == '') {
                  callback(new Error('请输入密码'))
                }
                if ([...this.userInfo.password].length < 6 || [...this.userInfo.password].length > 20) {
                  callback(new Error('密码应当在6到20位之间'))
                } else {
                  callback()
                }
              },
              trigger: 'blur',
            },
          ],
          checkpass: [
            {
              validator: (rule, value, callback) => {
                if (this.userInfo.checkpass == '') {
                  callback(new Error('请输入密码'))
                }
                if (this.userInfo.password !== this.userInfo.checkpass) {
                  callback(new Error('密码不一致，请重新输入'))
                } else {
                  callback()
                }
              },
              trigger: 'blur',
            },
          ],
        },
        addUserRules: {
          username: [
            {
              validator: (rule, value, callback) => {
                if (this.newUser.username == '') {
                  callback(new Error('请输入用户名'))
                }
                if ([...this.newUser.username].length < 4 || [...this.newUser.username].length > 12) {
                  callback(new Error('用户名应当在4到12个字符之间'))
                } else {
                  callback()
                }
              },
              trigger: 'blur',
            },
          ],
          password: [
            {
              validator: (rule, value, callback) => {
                if (this.newUser.password == '') {
                  callback(new Error('请输入密码'))
                }
                if ([...this.newUser.password].length < 6 || [...this.newUser.password].length > 20) {
                  callback(new Error('密码应当在6到20位之间'))
                } else {
                  callback()
                }
              },
              trigger: 'blur',
            },
          ],
          checkpass: [
            {
              validator: (rule, value, callback) => {
                if (this.newUser.checkpass == '') {
                  callback(new Error('请输入密码'))
                }
                if (this.newUser.password !== this.newUser.checkpass) {
                  callback(new Error('密码不一致，请重新输入'))
                } else {
                  callback()
                }
              },
              trigger: 'blur',
            },
          ],
        },
        changePasswordRules: {
          password: [
            {
              validator: (rule, value, callback) => {
                if (this.changePassword.password == '') {
                  callback(new Error('请输入密码'))
                }
                if ([...this.changePassword.password].length < 6 || [...this.changePassword.password].length > 20) {
                  callback(new Error('密码应当在6到20位之间'))
                } else {
                  callback()
                }
              },
              trigger: 'blur',
            },
          ],
          checkpass: [
            {
              validator: (rule, value, callback) => {
                if (this.changePassword.checkpass == '') {
                  callback(new Error('请输入密码'))
                }
                if (this.changePassword.password !== this.changePassword.checkpass) {
                  callback(new Error('密码不一致，请重新输入'))
                } else {
                  callback()
                }
              },
              trigger: 'blur',
            },
          ],
        },
        editUserVisible: false,
        addUserVisible: false,
        changePasswordVisible: false,
      }
    },
    created() {
      this.getUserList()
    },
    mounted() {
      this.headers = { Authorization: `Bearer ${window.sessionStorage.getItem('token')}` }
    },
    computed: {
      IsAdmin: function () {
        if (this.userInfo.role === 1) {
          return true
        } else {
          return false
        }
      },
    },
    methods: {
      CategoryChange(value) {
        this.queryParam.srcType = value
        this.getUserList()
      },
      // 获取用户列表
      async getUserList() {
        const { data: res } = await this.$http.get('user/audits', {
          params: {
            src_type: this.queryParam.srcType,
            pagesize: this.queryParam.pagesize,
            pagenum: this.queryParam.pagenum,
          },
        })
        this.userlist = res.data
        this.pagination.total = res.total
      },
  
      // 搜索用户
      async searchUser() {
        this.queryParam.pagenum = 1
        this.pagination.current = 1
        const { data: res } = await this.$http.get('/users', {
          params: {
            username: this.queryParam.username,
            pagesize: this.queryParam.pagesize,
            pagenum: this.queryParam.pagenum,
          },
        })
        if (res.status !== 200) return this.$message.error(res.message)
        this.userlist = res.data
        this.pagination.total = res.total
      },
  
      // 更改分页
      handleTableChange(pagination, filters, sorter) {
        var pager = { ...this.pagination }
        pager.current = pagination.current
        pager.pageSize = pagination.pageSize
        this.queryParam.pagesize = pagination.pageSize
        this.queryParam.pagenum = pagination.current
  
        if (pagination.pageSize !== this.pagination.pageSize) {
          this.queryParam.pagenum = 1
          pager.current = 1
        }
        this.pagination = pager
        this.getUserList()
      },
      // 删除用户
      deleteUser(id) {
        this.$confirm({
          title: '提示：请再次确认',
          content: '确定要删除该用户吗？一旦删除，无法恢复',
          onOk: async () => {
            const { data: res } = await this.$http.post(`user/delete/${id}`)
            if (res.status != 200) return this.$message.error(res.message)
            this.$message.success('删除成功')
            this.getUserList()
          },
          onCancel: () => {
            this.$message.info('已取消删除')
          },
        })
      },
      // 新增用户
      addUserOk() {
        this.$refs.addUserRef.validate(async (valid) => {
          if (!valid) return this.$message.error('参数不符合要求，请重新输入')
          const { data: res } = await this.$http.post('user/add', {
            username: this.newUser.username,
            password: this.newUser.password,
            role: this.newUser.role,
          })
          if (res.status != 200) return this.$message.error(res.message)
          this.$refs.addUserRef.resetFields()
          this.addUserVisible = false
          this.$message.success('添加用户成功')
          this.getUserList()
        })
      },
      addUserCancel() {
        this.$refs.addUserRef.resetFields()
        this.addUserVisible = false
        this.$message.info('新增用户已取消')
      },
      adminChange(checked) {
        if (checked) {
          this.userInfo.role = 1
        } else {
          this.userInfo.role = 2
        }
      },
      // 上传图片
      upChange(info) {
        if (info.file.status !== 'uploading') {
        }
        if (info.file.status === 'done') {
          this.$message.success(`图片上传成功`)
          this.userInfo.avatar = info.file.response.url
        } else if (info.file.status === 'error') {
          this.$message.error(`图片上传失败`)
        }
      },
      // 编辑用户
      async editUser(id) {
        this.editUserVisible = true
        const { data: res } = await this.$http.get(`user/${id}`)
        this.userInfo = res.data
        this.userInfo.id = id
      },
      editUserOk() {
        this.$refs.addUserRef.validate(async (valid) => {
          if (!valid) return this.$message.error('参数不符合要求，请重新输入')
          const { data: res } = await this.$http.post(`user/edit`, {
            id:this.userInfo.id,
            username: this.userInfo.username,
            nickname: this.userInfo.nickname,
            role: this.userInfo.role,
            avatar: this.userInfo.avatar,
          })
          if (res.status != 200) return this.$message.error(res.message)
          this.editUserVisible = false
          this.$message.success('更新用户信息成功')
          this.getUserList()
        })
      },
      editUserCancel() {
        this.$refs.addUserRef.resetFields()
        this.editUserVisible = false
        this.$message.info('编辑已取消')
      },
  
      // 修改密码
      async ChangePassword(id) {
        this.changePasswordVisible = true
        const { data: res } = await this.$http.get(`user/${id}`)
        this.changePassword.id = id
      },
      async ChangeUserStatus(id, status) {
          const { data: res } = await this.$http.post(`user/status`, {
            status: status,
            id: id,
          })
          if (res.status != 200) return this.$message.error(res.message)
          this.$message.success('操作成功')
      },
  
      changePasswordOk() {
        this.$refs.changePasswordRef.validate(async (valid) => {
          if (!valid) return this.$message.error('参数不符合要求，请重新输入')
          const { data: res } = await this.$http.put(`admin/changepw/${this.changePassword.id}`, {
            password: this.changePassword.password,
          })
          if (res.status != 200) return this.$message.error(res.message)
          this.changePasswordVisible = false
          this.$message.success('修改密码成功')
          this.getUserList()
        })
      },
      changePasswordCancel() {
        this.$refs.changePasswordRef.resetFields()
        this.changePasswordVisible = false
        this.$message.info('已取消')
      },
    },
  }
  </script>
  
  <style scoped>
  .actionSlot {
    display: flex;
    height: 20px;
    justify-content: center;
  }
  .AvatarImg img {
      width: 40px;
      height: 40px;
  }
  </style>
  