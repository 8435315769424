<template>
  <div>
    <a-card>
      <h3>{{ id ? '编辑话题' : '新增话题' }}</h3>

      <a-form-model :model="topicInfo" ref="topicInfoRef" :rules="topicInfoRules"
        :hideRequiredMark="true">
        <a-row :gutter="24">
          <a-col :span="16">
            <a-form-model-item label="名称" prop="name">
              <a-input style="width: 300px" v-model="topicInfo.name"></a-input>
            </a-form-model-item>
            <a-form-model-item label="描述" prop="desc">
              <a-input type="textarea" v-model="topicInfo.desc"></a-input>
            </a-form-model-item>
            <a-form-model-item label="话题icon" prop="url">
              <a-input v-model="topicInfo.url"></a-input>
            </a-form-model-item>
            <a-form-model-item label="话题封面" prop="cover">
              <a-input v-model="topicInfo.cover"></a-input>
            </a-form-model-item>
          </a-col>
          <a-col :span="8">
            <a-form-model-item label="话题分类" prop="cid">
              <a-select style="width: 200px" v-model="topicInfo.cid" placeholder="请选择分类"
                @change="cateChange">
                <a-select-option v-for="item in Catelist" :key="item.id" :value="item.id">
                  {{
                      item.name
                  }}
                </a-select-option>
              </a-select>
            </a-form-model-item>
          </a-col>
        </a-row>
      

        <a-form-model-item>
          <a-button type="danger" style="margin-right: 15px" @click="artOk(topicInfo.id)">
            {{
                topicInfo.id ? '更新' : '提交'
            }}
          </a-button>
          <a-button type="primary" @click="addCancel">取消</a-button>
        </a-form-model-item>
      </a-form-model>
    </a-card>
  </div>
</template>

<script>
import { Url } from '../../plugin/http'
export default {
  props: ['id'],
  data() {
    return {
      topicInfo: {
        id: 0,
        name: '',
        cid: 0,
        url: '',
        cover: '',
        desc: '',
        created_at: '',
        updated_at: '',
      },
      Catelist: [],
      upUrl: Url + 'upload',
      headers: {},
      fileList: [],
      topicInfoRules: {
        name: [{ required: true, message: '请输入话题名称', trigger: 'change' }],
        cid: [{ required: true, message: '请选择话题分类', trigger: 'change' }],
      },
    }
  },
  mounted() {
    this.getCateList()
    this.headers = { Authorization: `Bearer ${window.sessionStorage.getItem('token')}` }
    if (this.id) {
      this.gettopicInfo(this.id)
    }
  },
  methods: {
    // 查询话题信息
    async gettopicInfo(id) {
      const { data: res } = await this.$http.get(`topic/${id}`)
      if (res.code !== 0) {
        this.$message.error(res.message)
        return
      }
      this.topicInfo = res.data
      this.topicInfo.id = res.data.id
    },
    // 获取话题列表
    async getCateList() {
      const { data: res } = await this.$http.get('admin/category')
      if (res.status !== 200) return this.$message.error(res.message)
      this.Catelist = res.data
    },
    // 选择话题
    cateChange(value) {
      this.topicInfo.cid = value
    },
    // 选择难度
    levelChange(value) {
      this.topicInfo.level = value
    },
    // 上传图片
    upChange(info) {
      if (info.file.status !== 'uploading') {
      }
      if (info.file.status === 'done') {
        this.$message.success(`图片上传成功`)
        this.topicInfo.img = info.file.response.url
      } else if (info.file.status === 'error') {
        this.$message.error(`图片上传失败`)
      }
    },
    // 提交&&更新话题
    artOk(id) {
      this.$refs.topicInfoRef.validate(async (valid) => {
        if (!valid) return this.$message.error('参数验证未通过，请按要求录入话题内容')
        if (id === 0) {
          const { data: res } = await this.$http.post('topic/add', this.topicInfo)
          if (res.status !== 200) return this.$message.error(res.message)
          this.$router.push('/questionlist')
          this.$message.success('添加话题成功')
        } else {
          this.topicInfo.created_at = null
          this.topicInfo.updated_at = null
          const { data: res } = await this.$http.post(`topic/edit/${id}`, this.topicInfo)
          if (res.status !== 200) return this.$message.error(res.message)

          this.$router.push('/questionlist')
          this.$message.success('更新话题成功')
        }
      })
    },

    addCancel() {
      this.$refs.topicInfoRef.resetFields()
    },
  },
}
</script>